import { message } from "antd";
import CustomAxios from "../api.service";
import { ENDPOINTS } from "../constants";

export const TIMING_EXPENDITURE_SET_RENAME = "TIMING_EXPENDITURE_SET_RENAME";
export const TIMING_EXPENDITURE_RESET_STATE = "TIMING_EXPENDITURE_RESET_STATE";
export const TIMING_EXPENDITURE_SET_TABLE_UNSAVED_CHANGES =
  "TIMING_EXPENDITURE_SET_TABLE_UNSAVED_CHANGES";
export const TIMING_EXPENDITURE_SET_DELETE_ITEM =
  "TIMING_EXPENDITURE_SET_DELETE_ITEM";
export const TIMING_EXPENDITURE_SET_ADD_ITEM =
  "TIMING_EXPENDITURE_SET_ADD_ITEM";
export const TIMING_EXPENDITURE_SET_TABLE = "TIMING_EXPENDITURE_SET_TABLE";
export const TIMING_EXPENDITURE_SET_TABLE_CALCULATED =
  "TIMING_EXPENDITURE_SET_TABLE_CALCULATED";
export const TIMING_EXPENDITURE_SET_LOADING = "TIMING_EXPENDITURE_SET_LOADING";
export const TIMING_EXPENDITURE_EDIT_YEAR_INPUT =
  "TIMING_EXPENDITURE_EDIT_YEAR_INPUT";

export const timingExpenditureInputGetData =
  (body, calculated) => (dispatch) => {
    dispatch({ type: TIMING_EXPENDITURE_SET_LOADING, payload: true });
    CustomAxios(ENDPOINTS.timingExpenditureInputGet, "POST", body)
      .then((val) => {
        dispatch({
          type: calculated
            ? TIMING_EXPENDITURE_SET_TABLE_CALCULATED
            : TIMING_EXPENDITURE_SET_TABLE,
          payload: val.data,
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch({ type: TIMING_EXPENDITURE_SET_LOADING, payload: false });
      });
  };
export const timingExpenditureInputSaveData =
  (body, checkChange) => (dispatch, getState) => {
    const timingEx = getState().timingExInput;
    if (checkChange && !timingEx.unsaved_changes) {
      return;
    }
    body = {
      ...body,
      ...timingEx.data,
    };
    delete body.subtotal_capital_expense;
    delete body.subtotal_project_expense;
    delete body.amount_requested;
    dispatch({ type: TIMING_EXPENDITURE_SET_LOADING, payload: true });
    CustomAxios(ENDPOINTS.timingExpenditureInputSave, "POST", body)
      .then(() => {
        dispatch({
          type: TIMING_EXPENDITURE_SET_TABLE_UNSAVED_CHANGES,
          payload: false,
        });
        dispatch(
          timingExpenditureInputGetData({ project_id: body.project_id }, false)
        );
        message.success("Saved Timing of Expenditure");
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch({ type: TIMING_EXPENDITURE_SET_LOADING, payload: false });
      });
  };
