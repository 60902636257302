import CustomAxios from "../api.service";
import { ENDPOINTS } from "../constants";

export const RESULTS_VALIDATE_RESET_STATE = "RESULTS_VALIDATE_RESET_STATE";
export const RESULTS_VALIDATE_SET_TABLE = "RESULTS_VALIDATE_SET_TABLE";
export const RESULTS_VALIDATE_SET_LOADING = "RESULTS_VALIDATE_SET_LOADING";

export const ResultsGetData = (body) => (dispatch) => {
  dispatch({ type: RESULTS_VALIDATE_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.resultValidate, "POST", body)
    .then((val) => {
      let years = Object.keys(val.data.total_benefits);
      let graph = years.map((val1) => ({
        name: Number(val1),
        "Total Benefits": val.data.total_benefits[val1],
        "Total Costs": val.data.total_costs[val1],
      }));
      let graph_cash = years.map((val1) => ({
        name: Number(val1),
        "Cumul Cash Flow": val.data.cummulative_cash_flow[val1],
        "Cumul EP": val.data.cumulative_economic_profit[val1],
      }));
      dispatch({
        type: RESULTS_VALIDATE_SET_TABLE,
        payload: { ...val.data, graph, graph_cash },
      });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: RESULTS_VALIDATE_SET_LOADING, payload: false });
    });
};
