import {
  PL_BA_CALCULATION_SET_LOADING,
  PL_BA_CALCULATION_SET_TABLE,
  PL_CALCULATION_RESET_STATE,
  PL_CALCULATION_SET_LOADING,
  PL_CALCULATION_SET_TABLE,
} from "../actions/pl_calculations";

const initailState = {
  year_column: [],
  year_column2: [],
  loading: false,
  loading_ba: false,
  data: {},
  data_ba: {},
  unsaved_changes: false,
};

export const PLCalcualteReducer = (state = initailState, action) => {
  switch (action.type) {
    case PL_CALCULATION_SET_TABLE: {
      let d = action.payload;
      if (d.years_list) {
        let year = [...d.years_list];
        delete d.years_list;
        return {
          ...state,
          data: d,
          year_column: year,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case PL_BA_CALCULATION_SET_TABLE: {
      let d = action.payload;
      if (d.years_list) {
        let year = [...d.years_list];
        delete d.years_list;
        return {
          ...state,
          data_ba: d,
          year_column2: year,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case PL_CALCULATION_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case PL_BA_CALCULATION_SET_LOADING:
      return {
        ...state,
        loading_ba: action.payload,
      };
    case PL_CALCULATION_RESET_STATE:
      return {
        ...initailState,
      };
    default:
      return state;
  }
};
