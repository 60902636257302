import { message } from "antd";
import CustomAxios from "../api.service";
import { ENDPOINTS } from "../constants";
import { cloneDeep } from "../utils/mathFn";
import { growthInputGetData } from "./growthInput.action";

export const VOLUME_ASSUMPTION_SET_UNSAVED_CHANGES =
  "VOLUME_ASSUMPTION_SET_UNSAVED_CHANGES";
export const VOLUME_ASSUMPTION_RESET_STATE = "VOLUME_ASSUMPTION_RESET_STATE";
export const VOLUME_ASSUMPTION_SET_SUMMARY = "VOLUME_ASSUMPTION_SET_SUMMARY";
export const VOLUME_ASSUMPTION_SET_TABS = "VOLUME_ASSUMPTION_SET_TABS";
export const VOLUME_ASSUMPTION_SET_LOADING = "VOLUME_ASSUMPTION_SET_LOADING";
export const VOLUME_ASSUMPTION_ADD_TAB = "VOLUME_ASSUMPTION_ADD_TAB";
export const VOLUME_ASSUMPTION_RENAME_TAB = "VOLUME_ASSUMPTION_RENAME_TAB";
export const VOLUME_ASSUMPTION_REMOVE_TAB = "VOLUME_ASSUMPTION_REMOVE_TAB";
export const VOLUME_ASSUMPTION_EDIT_YEAR_INPUT =
  "VOLUME_ASSUMPTION_EDIT_YEAR_INPUT";

export const volumeAssumptionGetData = (body) => (dispatch) => {
  dispatch({ type: VOLUME_ASSUMPTION_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.volumeAssumptionGet, "POST", body)
    .then((val) => {
      dispatch({ type: VOLUME_ASSUMPTION_SET_TABS, payload: val.data });
    })
    .catch(() => {
      message.error("Failed to load Tab data");
    })
    .finally(() => {
      dispatch({ type: VOLUME_ASSUMPTION_SET_LOADING, payload: false });
    });
};
export const volumeAssumptionDeleteTab = (body) => (dispatch) => {
  dispatch({ type: VOLUME_ASSUMPTION_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.volumeAssumptionDeleteTab, "POST", body)
    .then(() => {
      dispatch(volumeAssumptionGetSummary({ project_id: body.project_id }));
      dispatch({ type: VOLUME_ASSUMPTION_REMOVE_TAB, payload: body.index });
    })
    .catch(() => {
      message.error("Failed to Delete Tab");
    })
    .finally(() => {
      dispatch({ type: VOLUME_ASSUMPTION_SET_LOADING, payload: false });
    });
};
export const volumeAssumptionAddTab = (body) => (dispatch, getState) => {
  const volume = getState().volumeAssumption;
  let years = Object.keys(volume.volume_assumption[0].volume);
  let placeHolder = {};
  years.forEach((val) => {
    placeHolder[val] = null;
  });
  let tab = {
    project_id: body.project_id,
    item: [
      {
        item_name: `Item ${volume.volume_assumption.length + 1}`,
        volume: cloneDeep(placeHolder),
        gsv: cloneDeep(placeHolder),
        created_by: null,
        created_date: "",
        last_modified_by: null,
        last_modified_date: "",
      },
    ],
  };
  dispatch({ type: VOLUME_ASSUMPTION_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.volumeAssumptionAddTab, "POST", tab)
    .then((val) => {
      dispatch({
        type: VOLUME_ASSUMPTION_ADD_TAB,
        payload: { ...tab.item[0], item_id: val.data.item_id },
      });
    })
    .catch(() => {
      message.error("Failed to Add Tab");
    })
    .finally(() => {
      dispatch({ type: VOLUME_ASSUMPTION_SET_LOADING, payload: false });
    });
};
export const volumeAssumptionGetSummary = (body) => (dispatch) => {
  dispatch({ type: VOLUME_ASSUMPTION_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.volumeAssumptionSummary, "POST", body)
    .then((val) => {
      dispatch({ type: VOLUME_ASSUMPTION_SET_SUMMARY, payload: val.data });
    })
    .catch(() => {
      message.error("Failed to load Summary");
    })
    .finally(() => {
      dispatch({ type: VOLUME_ASSUMPTION_SET_LOADING, payload: false });
    });
};
export const volumeAssumptionSaveTabs =
  (body, checkChange) => (dispatch, getState) => {
    const volume = getState().volumeAssumption;
    if (checkChange && !volume.unsaved_changes) {
      return;
    }
    dispatch({ type: VOLUME_ASSUMPTION_SET_LOADING, payload: true });
    CustomAxios(ENDPOINTS.volumeAssumptionSave, "POST", {
      ...body,
      item: volume.volume_assumption.map((val) => ({
        ...val,
      })),
    })
      .then(() => {
        dispatch({
          type: VOLUME_ASSUMPTION_SET_UNSAVED_CHANGES,
          payload: false,
        });
        dispatch(volumeAssumptionGetSummary(body));
        dispatch(growthInputGetData({ project_id: body.project_id }, true));
        message.success("Saved Volume Assumption");
      })
      .catch(() => {
        message.error("Save Failed");
      })
      .finally(() => {
        dispatch({ type: VOLUME_ASSUMPTION_SET_LOADING, payload: false });
      });
  };
