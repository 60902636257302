import {
  RESULTS_VALIDATE_RESET_STATE,
  RESULTS_VALIDATE_SET_LOADING,
  RESULTS_VALIDATE_SET_TABLE,
} from "../actions/results.action";

const initailState = {
  year_column: [],
  loading: false,
  data: {},
  unsaved_changes: false,
};

export const ResultsReducer = (state = initailState, action) => {
  switch (action.type) {
    case RESULTS_VALIDATE_SET_TABLE:
      return {
        ...state,
        data: action.payload,
      };
    case RESULTS_VALIDATE_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case RESULTS_VALIDATE_RESET_STATE:
      return {
        ...initailState,
      };
    default:
      return state;
  }
};
