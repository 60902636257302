import React from "react";

const colors = [
  { color: "rgb(255, 71, 26)", height: "10%"},
  { color: "rgb(246, 150, 30)", height: "20%"},
  { color: "rgb(236, 219, 35)", height: "20%"},
  { color: "rgb(174, 226, 40)", height: "16.6%"},
  { color: "rgb(106, 215, 45)", height: "16.6%"},
  { color: "#4caf50", height: "16.6%"},
].reverse();
function Thermometer({ value = 0 }) {
  return (
    <div className="thermometer-container">
      <div className="bar-card">
        <div className="bar-title">IRR</div>
        <div className="bar-outline">
          <div className="bar">
            {colors.map((val, i) => (
              <div
                className={`sector ${
                  i === colors.length - 1 ? "last-sector" : ""
                }`}
                key={i}
                style={{ backgroundColor: val.color, height: val.height }}
              ></div>
            ))}
            <div
              className="refer-line"
              style={{
                bottom: `${value < 0 ? 0 : value > 100 ? 100 : value}%`,
              }}
            >
              <div>{value}%</div>
            </div>
            <div
              className="bar-pointer bar-left"
              style={{
                backgroundColor: "rgb(255, 71, 26)",
                bottom: `${5 - 2.9}%`,
              }}
            >
              <div>
                5%
                <div className="arrow"></div>
              </div>
            </div>
            <div
              className="bar-pointer bar-right"
              style={{
                backgroundColor: "rgb(246, 150, 30)",
                bottom: `${20 - 2.9}%`,
              }}
            >
              <div>
                20%
                <div className="arrow"></div>
              </div>
            </div>
            <div
              className="bar-pointer bar-left"
              style={{
                backgroundColor: "rgb(236, 219, 35)",
                bottom: `${40 - 2.9}%`,
              }}
            >
              <div>
                40%
                <div className="arrow"></div>
              </div>
            </div>
            <div
              className="bar-pointer bar-right"
              style={{
                backgroundColor: "rgb(174, 226, 40)",
                bottom: `${60 - 2.9}%`,
              }}
            >
              <div>
                60%
                <div className="arrow"></div>
              </div>
            </div>
            <div
              className="bar-pointer bar-left"
              style={{
                backgroundColor: "rgb(106, 215, 45)",
                bottom: `${75 - 2.9}%`,
              }}
            >
              <div>
                75%
                <div className="arrow"></div>
              </div>
            </div>
            <div
              className="bar-pointer bar-right"
              style={{ backgroundColor: "#4caf50", bottom: `${100 - 2.9}%` }}
            >
              <div>
                100%
                <div className="arrow"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Thermometer;
