import {
  USER_SET_USERNAME,
  USER_SET_SIMULATION_ID,
} from "../actions/user.action";

const initailState = {
  username: "",
  id: "",
  is_superuser: false,
  is_staff:false,
  growth_id: "",
  saving_id: "",
};

export const UserReducer = (state = initailState, action) => {
  switch (action.type) {
    case USER_SET_USERNAME:
      return {
        ...state,
        ...action.payload,
      };
    case USER_SET_SIMULATION_ID:
      return {
        ...state,
        [action.payload.id]: action.payload.value,
      };
    default:
      return state;
  }
};
