import React, { useEffect,useState } from 'react';
import { Modal, Button } from 'antd';
import formula from "../../assets/formula/Formula.xlsx"
import * as XLSX from "xlsx";

export default function FormulaHelp() 
{
   const [state, setState] = useState([]);
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    function load_data(){
    const targetCsvPath = formula
    const reader = new FileReader();
    reader.onload = function (e) {
  const workbook = XLSX.read(e.target.result, { type: "binary" });
  const data = XLSX.utils.sheet_to_json(workbook.Sheets.FORMULAS)
  setState(data)
   }
   fetch(targetCsvPath)
   .then((response) => response.blob())
    .then((data) => {
      reader.readAsBinaryString(data);
    })
    .catch((err) => console.log(err));

    }
   
    const showModal = () => {
      load_data()
      setIsModalVisible(true); 
    };
  
    const handleOk = () => {
      setIsModalVisible(false);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
    return (
      <>
        <u onClick={showModal} >   formulas available here</u>
        <Modal width={1000} title={'Formula list'} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>

            { isModalVisible && 
            <div style={{height: "350px",overflow: "auto"}}>
            <table border='10' frame='void' rules='columns' >
              <thead>
                <tr>
                  <th>SCREEN</th>
                  <th>KPI</th>
                  <th>FORMULA</th>
                </tr>              
              </thead>
              <tbody>
                {state.map((val) => (
                  <tr key={val[0]}>
                    <td>{val['SCREEN']}</td>
                    <td>{val['KPI']}</td>
                    <td>{val['FORMULA']}</td>
                   
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
        }
        </Modal>
      </>
    );
}