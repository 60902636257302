import CustomAxios from "../api.service";
import { ENDPOINTS } from "../constants";

export const SOURCE_EARNING_RESET_STATE = "SOURCE_EARNING_RESET_STATE";
export const SOURCE_EARNING_SET_TABLE = "SOURCE_EARNING_SET_TABLE";
export const SOURCE_EARNING_SET_LOADING = "SOURCE_EARNING_SET_LOADING";

export const SourceEarningGetData = (body) => (dispatch) => {
  dispatch({ type: SOURCE_EARNING_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.sourceEarning, "POST", body)
    .then((val) => {
      dispatch({ type: SOURCE_EARNING_SET_TABLE, payload: { ...val.data } });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: SOURCE_EARNING_SET_LOADING, payload: false });
    });
};
