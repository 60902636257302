import React, { useEffect, useRef, useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";

function SpeedGraph({
  title,
  currentValueText,
  value,
  maxValue,
  minValue,
  customSegmentStops,
}) {
  const card = useRef();
  const [width, setWidth] = useState(null);
  useEffect(() => {
    console.log(card.current.offsetWidth);
    setWidth(card.current.offsetWidth === 0 ? 350 : card.current.offsetWidth);
  }, []);
  return (
    <div className="react-speedo-container" ref={card}>
      <div className="title">{title}</div>
      {width && (
        <ReactSpeedometer
          width={width * 0.85}
          height={width * 0.6}
          minValue={minValue}
          maxValue={maxValue}
          customSegmentStops={customSegmentStops}
          value={value}
          labelFontSize={"0px"}
          currentValueText={currentValueText}
        />
      )}
    </div>
  );
}
export default SpeedGraph;
