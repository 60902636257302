import React from "react";

const colors = [
  {color: "rgb(255, 71, 26)", height: 1},
  {color: "rgb(246, 150, 30)", height: 1},
  {color: "rgb(236, 219, 35)", height: 1},
  {color: "rgb(174, 226, 40)", height: 2},
  {color: "rgb(106, 215, 45)", height: 5},
  {color: "#4caf50", height: 1},
].reverse();
function Thermometer({ value = 0 }) {
  return (
    <div className="thermometer-container">
      <div className="bar-card">
        <div className="bar-title">5Y CUM EP</div>
        <div className="bar-outline">
          <div className="bar">
            {colors.map((val, i) => (
              <div
                className={`sector ${
                  i === colors.length - 1 ? "last-sector" : ""
                }`}
                key={i}
                style={{ backgroundColor: val.color, height: `${(100/11)*val.height}%` }}
              ></div>
            ))}
            <div
              className="refer-line"
              style={{
                bottom: `${value < 0 ? 0 : ((100/11)*(value >11 ? 11 : (value + 1)))}%`,
              }}
            >
              <div>{value}m USD</div>
            </div>
            <div
              className="bar-pointer bar-left"
              style={{
                backgroundColor: "rgb(255, 71, 26)",
                bottom: `${100/11 - 2.9}%`,
              }}
            >
              <div>
                0 m
                <div className="arrow"></div>
              </div>
            </div>
            <div
              className="bar-pointer bar-right"
              style={{
                backgroundColor: "rgb(246, 150, 30)",
                bottom: `${(100/11)*2 - 2.9}%`,
              }}
            >
              <div>
                1 m
                <div className="arrow"></div>
              </div>
            </div>
            <div
              className="bar-pointer bar-left"
              style={{
                backgroundColor: "rgb(236, 219, 35)",
                bottom: `${(100/11)*3 - 2.9}%`,
              }}
            >
              <div>
                2 m
                <div className="arrow"></div>
              </div>
            </div>
            <div
              className="bar-pointer bar-right"
              style={{
                backgroundColor: "rgb(174, 226, 40)",
                bottom: `${(100/11)*5 - 2.9}%`,
              }}
            >
              <div>
                5 m
                <div className="arrow"></div>
              </div>
            </div>
            <div
              className="bar-pointer bar-left"
              style={{
                backgroundColor: "rgb(106, 215, 45)",
                bottom: `${(100/11)*10 - 2.9}%`,
              }}
            >
              <div>
                10 m
                <div className="arrow"></div>
              </div>
            </div>
            <div
              className="bar-pointer bar-right"
              style={{ backgroundColor: "#4caf50", bottom: `${100 - 2.9}%` }}
            >
              {/* <div>
                100%
                <div className="arrow"></div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Thermometer;
