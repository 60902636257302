import { message } from "antd";
import CustomAxios from "../api.service";
import { ENDPOINTS } from "../constants";

export const INVEST_INPUT_RESET_STATE = "INVEST_INPUT_RESET_STATE";
export const INVEST_INPUT_SET_TABLE_UNSAVED_CHANGES =
  "INVEST_INPUT_SET_TABLE_UNSAVED_CHANGES";
export const INVEST_INPUT_SET_TABLE = "INVEST_INPUT_SET_TABLE";
export const INVEST_INPUT_SET_TABLE_CALCULATED =
  "INVEST_INPUT_SET_TABLE_CALCULATED";
export const INVEST_INPUT_SET_LOADING = "INVEST_INPUT_SET_LOADING";
export const INVEST_INPUT_EDIT_YEAR_INPUT = "INVEST_INPUT_EDIT_YEAR_INPUT";

export const investInputGetData = (body, calculated) => (dispatch) => {
  dispatch({ type: INVEST_INPUT_SET_LOADING, payload: true });
  CustomAxios(
    calculated ? ENDPOINTS.investInputCalculate : ENDPOINTS.investInputGet,
    "POST",
    body
  )
    .then((val) => {
      dispatch({
        type: calculated
          ? INVEST_INPUT_SET_TABLE_CALCULATED
          : INVEST_INPUT_SET_TABLE,
        payload: { ...val.data },
      });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: INVEST_INPUT_SET_LOADING, payload: false });
    });
};
export const investInputSaveData =
  (body, checkChange) => (dispatch, getState) => {
    const invest = getState().investInput;
    if (checkChange && !invest.unsaved_changes) {
      return;
    }
    dispatch({ type: INVEST_INPUT_SET_LOADING, payload: true });
    CustomAxios(ENDPOINTS.investInputSave, "POST", { ...body, ...invest.data })
      .then(() => {
        dispatch({
          type: INVEST_INPUT_SET_TABLE_UNSAVED_CHANGES,
          payload: false,
        });
        dispatch(investInputGetData(body, true));
        message.success("Saved Investments");
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch({ type: INVEST_INPUT_SET_LOADING, payload: false });
      });
  };
