import {
  SOURCE_EARNING_RESET_STATE,
  SOURCE_EARNING_SET_LOADING,
  SOURCE_EARNING_SET_TABLE,
} from "../actions/sourceEarning.action";

const initailState = {
  year_column: [],
  loading: false,
  data: {},
  unsaved_changes: false,
};

export const SourceEarningReducer = (state = initailState, action) => {
  switch (action.type) {
    case SOURCE_EARNING_SET_TABLE: {
      let d = action.payload;
      if (d.years) {
        let year = [...d.years];
        delete d.years;
        return {
          ...state,
          data: d,
          year_column: year,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case SOURCE_EARNING_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SOURCE_EARNING_RESET_STATE:
      return {
        ...initailState,
      };
    default:
      return state;
  }
};
