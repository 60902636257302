import { message } from "antd";
import CustomAxios from "../api.service";
import { ENDPOINTS } from "../constants";

export const GROWTH_INPUT_RESET_STATE = "GROWTH_INPUT_RESET_STATE";
export const GROWTH_INPUT_SET_TABLE_UNSAVED_CHANGES =
  "GROWTH_INPUT_SET_TABLE_UNSAVED_CHANGES";
export const GROWTH_INPUT_SET_TABLE = "GROWTH_INPUT_SET_TABLE";
export const GROWTH_INPUT_SET_LOADING = "GROWTH_INPUT_SET_LOADING";
export const GROWTH_INPUT_SET_LOADING_CALC = "GROWTH_INPUT_SET_LOADING_CALC";
export const GROWTH_INPUT_EDIT_YEAR_INPUT = "GROWTH_INPUT_EDIT_YEAR_INPUT";

export const growthInputGetData = (body, calculate) => async (dispatch) => {
  dispatch({
    type: calculate ? GROWTH_INPUT_SET_LOADING_CALC : GROWTH_INPUT_SET_LOADING,
    payload: true,
  });
  let growth1, growth2;
  try {
    growth1 = await CustomAxios(
      calculate ? ENDPOINTS.growthInputCalculate : ENDPOINTS.growthInputGet,
      "POST",
      body
    );
    if (calculate) {
      growth2 = await CustomAxios(ENDPOINTS.growthInputGet2, "POST", body);
    } else {
      growth2 = { data: {} };
    }
    dispatch({
      type: GROWTH_INPUT_SET_TABLE,
      payload: { ...growth1.data, ...growth2.data },
    });
  } catch (err) {
    console.error(err);
  } finally {
    dispatch({
      type: calculate
        ? GROWTH_INPUT_SET_LOADING_CALC
        : GROWTH_INPUT_SET_LOADING,
      payload: false,
    });
  }
};
export const growthInputSaveData =
  (body, checkChange) => (dispatch, getState) => {
    const growth = getState().growthInput;
    if (checkChange && !growth.unsaved_changes) {
      return;
    }
    dispatch({ type: GROWTH_INPUT_SET_LOADING, payload: true });
    CustomAxios(ENDPOINTS.growthInputSave, "POST", { ...body, ...growth.data })
      .then(() => {
        dispatch(growthInputGetData(body, true));
        dispatch({
          type: GROWTH_INPUT_SET_TABLE_UNSAVED_CHANGES,
          payload: false,
        });
        message.success("Saved Growth Inputs");
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch({ type: GROWTH_INPUT_SET_LOADING, payload: false });
      });
  };
