import { SET_FOOTPRINT_SAVINGS_DATA, CLEAR_CARBON_SAVINGS_DATA } from "../../actions/carbon_footprint/types";

const initialState = {
  type: "Savings",
  updated: false,
  line_item: [],
  fossil_thermal_energy: {},
  fossil_electrical_energy: {},
  incremental_energy_consumption: [],
  input_incremental_Transport: [],
  calculated_incremental_transport: [],
  estimated_footprint: 0,
  tCO2: 0,
  carbon_footprint: 0
}


const SavingsReducer = function (state = initialState, action = {}) {
  
    switch (action.type) {
        case SET_FOOTPRINT_SAVINGS_DATA:
          let savingsData = Object.assign(state, {...action.payload});
        return {
          ...state,
          ...savingsData
        };
        case CLEAR_CARBON_SAVINGS_DATA:
        return {
          ...state,
          ...initialState
        };
      default:
        return state;
    }
  };

  export default SavingsReducer;