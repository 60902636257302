import { Col, Input, InputNumber, message, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import CustomAxios from "../../api.service";
import LoadingWrapper from "../../components/loading_wrapper";
import Thermometer from "../../components/thermometer";
import Thermometer_EP from "../../components/thermometer/themo_ep";
import { ENDPOINTS } from "../../constants";
import { useDecodedParams } from "../../utils/param";
import ProjectTitleBar from "../project_input/project_title_bar";
import { exportComponentAsPNG } from "react-component-export-image";
import { useDispatch, useSelector } from "react-redux";
import { USER_SET_SIMULATION_ID } from "../../actions/user.action";
import { formatNum } from "../../utils/mathFn";
import Thermometer_FATR from "../../components/thermometer/themo_fatr";
import growthVideo from '../../assets/videos/Digital IRR-20211117_Simul growth.mp4';
const rowList = [
  {
    name: "",
    child: [
      {
        name: "Simulation Name",
        key: "simulation_name",
        textInput: true,
        info: "",
      },
    ],
  },
  {
    name: "Start Investment",
    child: [
      {
        name: "Year",
        key: "investment_start_year",
        info: "",
      },
    ],
  },
  {
    name: "Start Savings",
    child: [
      {
        name: "Year",
        key: "savings_start_year",
        calculated: true,
        info: "",
      },
    ],
  },
  {
    name: "Oncosts (CVS)",
    child: [
      {
        name: "CVS, one off",
        key: "oncosts_cvs",
        info: "(kUSD)",
      },
    ],
  },
  {
    name: "CAB Investment",
    child: [
      {
        name: "Capital",
        key: "capital_expense",
        info: "(kUSD)",
      },
      {
        name: "Project Expense",
        key: "project_expense",
        info: "(kUSD)",
      },
      {
        name: "Tax Rate",
        key: "tax_rate",
        info: "(%)",
      },
    ],
  },
  {
    name: "Growth",
    child: [
      {
        name: "Incr GSV Year 3",
        key: "gsv_year_3",
        info: "(kUSD)",
      },
      {
        name: "Trade Year 3",
        key: "trade_year_3_perc",
        info: "(%)",
      },
      {
        name: "Prime",
        key: "prime",
        info: "(/T)",
      },
      {
        name: "Absolute Incr MCC*",
        key: "abs_mcc",
         info: "(kUSD)",
      },
      {
        name: "E2E LCC",
        key: "e2e_lcc",
        info: "(/T)",
      },
      {
        name: "A&P%",
        key: "ap_perc",
        info: "",
      },
    ],
  },
];
const result = [
  {
    name: "Incr GSV",
    key: "incr_gsv",
  },
  {
    name: "Incr NSV",
    key: "incr_nsv",
  },
  {
    name: "Prime",
    key: "prime",
  },
  {
    name: "Absolute Incr MCC*",
    key: "abs_mcc",
  },
  {
    name: "LCC",
    key: "lcc",
  },
  {
    name: "A&P",
    key: "ap",
  },
  {
    name: "F&S and G&A",
    key: "fs_ga",
  },
  {
    name: "Earnings",
    key: "earnings",
  },
  {
    name: "Nopat",
    key: "nopat",
  },
  {
    name: "Capital Charge",
    key: "capital_charge",
  },
  {
    name: "EP",
    key: "ep",
  },
  {
    name: "NSV/T",
    key: "nsv",
    decimal: 2,
  },
  {
    name: "Incr MAC*%",
    key: "incr_mac_perc",
    decimal: 2,
  },
];
function check_key(key)
{
  if (['incr_tons','tax_rate','oncosts_cvs','project_expense','capital_expense','investment_start_year','gsv_year_3','trade_year_3_perc','prime','abs_mcc','e2e_lcc','ap_perc'].includes(key))
  return true
  else
  return false
}
function TableRows({ parent, data, handleChange }) {
  return (
    <>
      <tr>
        <td></td>
        <td></td>
      </tr>
      <tr className="sub-title">
        <td>{parent.name}</td>
        <td></td>
      </tr>
      {parent.child.map((val1) => (
        <tr key={val1.key}>
          <td>
            {val1.name} <span className="dim-info">{val1.info}</span>
          </td>
          <td>
            {val1.calculated ? (
              data[val1.key]
            ) : val1.textInput ? (
              <Input
                value={data[val1.key]}
                onChange={(e) => handleChange(val1.key, e.target.value)}
              />
            ) : (
              <InputNumber
                precision=
                {check_key(val1.key)?0:3}
                value={data[val1.key]}
                onChange={(e) => handleChange(val1.key, e,undefined,val1.name)}
              />
            )}
          </td>
        </tr>
      ))}
    </>
  );
}
let inputStateInit = {
  simulation_name: "",
  investment_start_year: "",
  capital_expense: "",
  project_expense: "",
  oncosts_cvs: "",
  tax_rate: "",
  gsv_year_3: "",
  trade_year_3_perc: "",
  prime: "",
  abs_mcc: "",
  e2e_lcc: "",
  ap_perc: "",
  incr_tons: [0, 0, 0, 0, 0],
  unsavedChanges: false,
};
let calcStateinit = {
  irr_calc: 0,
  marg_fatr: 0,
  cum_5y_ep: 0,
  savings_start_year: "",
  calcperyear: [],
  years_list: [],
  simulation_id: "",
};
function GrowthSimulation() {
  const [loading, setloading] = useState(false);
  const { id } = useDecodedParams();
  const [inputState, setInputState] = useState({ ...inputStateInit });
  const [calState, setcalState] = useState({ ...calcStateinit });
  const userState = useSelector((s) => s.user);
  const dispatch = useDispatch();
  let cardRef = useRef();
  function checkwholenumber(val)
  {
    if (val.toString().indexOf('.')>-1)
        return true
    return false
  }

 
  function handleChange(key, value, ton,name) 
  {
    if (check_key(key)==true&& value!=null)
    {
      if (checkwholenumber(value))
      {
        value=Number(value.toString().split(".")[0])
        message.warning(name + ' accept only whole number',2)
      }
      
    }
    let tons = [...inputState.incr_tons];
    if (ton !== undefined) {
      tons[ton] = value;
      setInputState({
        ...inputState,
        incr_tons: tons,
        unsavedChanges: true,
      });
    } else {
      setInputState({
        ...inputState,
        [key]: value,
        unsavedChanges: true,
      });
    }
  }
  function verify_input() {
    if (
      inputState.simulation_name !== "" &&
      inputState.simulation_name !== null &&
      inputState.investment_start_year !== "" &&
      inputState.investment_start_year !== null &&
      inputState.capital_expense !== "" &&
      inputState.capital_expense !== null &&
      inputState.project_expense !== "" &&
      inputState.project_expense !== null &&
      inputState.oncosts_cvs !== "" &&
      inputState.oncosts_cvs !== null &&
      inputState.tax_rate !== "" &&
      inputState.tax_rate !== null &&
      inputState.gsv_year_3 !== "" &&
      inputState.gsv_year_3 !== null &&
      inputState.trade_year_3_perc !== "" &&
      inputState.trade_year_3_perc !== null &&
      inputState.prime !== "" &&
      inputState.prime !== null &&
      inputState.abs_mcc !== "" &&
      inputState.abs_mcc !== null &&
      inputState.e2e_lcc !== "" &&
      inputState.e2e_lcc !== null &&
      inputState.ap_perc !== "" &&
      inputState.ap_perc !== null &&
      inputState.incr_tons.filter((val) => val === null).length === 0
    ) {
      return true;
    }
    return false;
  }
  useEffect(() => {
    if (id) {
      CalculateData(id);
    } else if (userState.growth_id) {
      CalculateData(userState.growth_id);
    }
  }, []);
  function handleSaveCalculate() {
    // const url
    if (!calState.simulation_id) {
      if (verify_input()) {
        let body = { ...inputState };
        delete body.unsavedChanges;
        setloading(true);
        CustomAxios(ENDPOINTS.growthSimulationSave, "POST", body)
          .then((val) => {
            dispatch({
              type: USER_SET_SIMULATION_ID,
              payload: { id: "growth_id", value: val.data.simulation_id },
            });
            CalculateData(val.data.simulation_id);
          })
          .catch((err) => {
            if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data.simulation_name
            ) {
              message.error(
                `Simulation with name "${inputState.simulation_name}" already exists!`
              );
            } else if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data.Error
            ) {
              message.error(err.response.data.Error);
            } else {
              message.error(`Unable to save simulation`);
            }
          })
          .finally(() => {
            setloading(false);
          });
      }
    } else {
      if (inputState.unsavedChanges) {
        let body = { ...inputState, simulation_id: calState.simulation_id };
        delete body.unsavedChanges;
        setloading(true);
        CustomAxios(ENDPOINTS.growthSimulationSave, "PUT", body)
          .then(() => {
            setInputState({ ...inputState, unsavedChanges: false });
            CalculateData(calState.simulation_id);
          })
          .catch((err) => {
            if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data.Error
            ) {
              message.error(err.response.data.Error);
            } else {
              message.error(`Unable to save simulation`);
            }
          })
          .finally(() => {
            setloading(false);
          });
      } else {
        CalculateData(calState.simulation_id);
      }
    }
  }
  function CalculateData(uid) {
    setloading(true);
    CustomAxios(ENDPOINTS.growthSimulationGet + uid, "GET")
      .then((val) => {
        let input = {};
        for (let x in inputState) {
          if (x !== "incr_tons") {
            input[x] =
              x === "simulation_name" ? val.data[x] : Number(val.data[x]);
          }
        }
        input.incr_tons = val.data.calcperyear.map((vali) => vali.incr_tons);
        setInputState({ ...inputState, ...input });
        setcalState({
          ...calState,
          ...val.data.calc[0],
          calcperyear: val.data.calcperyear,
          years_list: val.data.calcperyear.map((val4) => val4.year),
          simulation_id: val.data.simulation_id,
        });
      })
      .finally(() => {
        setloading(false);
      });
  }
  let ep = Number(calState["cum_5y_ep"]) / 1000;
  return (
    <div className="simulation-container">
      <LoadingWrapper loading={loading}>
        <ProjectTitleBar
          saveDisabled={!verify_input()}
          save={handleSaveCalculate}
          projectDetail={{}}
          noProjectName={true}
          downloadButton={true}
          video={growthVideo}
          video_title="Growth Simulation"
          onDownload={() => {
            exportComponentAsPNG(cardRef, {
              fileName: "Growth Simulation _" + inputState.simulation_name,
            });
          }}
          downloadDisabled={loading}
        />
        <Row
          gutter={15}
          ref={cardRef}
          style={{ backgroundColor: "#f1f1f1", padding: "10px 0" }}
        >
          <Col span={10}>
            <div className="simulation-input-container">
              <div>
                <table className="simulation-table">
                  <tbody>
                    {rowList.slice(0, 5).map((val, i) => (
                      <TableRows
                        key={i}
                        parent={val}
                        data={{ ...inputState, ...calState }}
                        handleChange={handleChange}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
              <div>
                <table className="simulation-table">
                  <tbody>
                    {rowList.slice(5).map((val, i) => (
                      <TableRows
                        key={i}
                        parent={val}
                        data={{ ...inputState }}
                        handleChange={handleChange}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <table className="simulation-table-result">
              <thead>
                <tr>
                  <th></th>
                  {calState.years_list.length > 0 ? (
                    calState.years_list.map((val, i) => <th key={i}>{val}</th>)
                  ) : (
                    <>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </>
                  )}
                </tr>
                <tr>
                  <th></th>
                  {["Y0", "Y1", "Y2", "Y3", "Y4"].map((val, i) => (
                    <th key={i}>{val}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Incr Tons</td>
                  {inputState.incr_tons.map((val, i) => (
                    <td key={i}>
                      {i === 0 ? (
                        0
                      ) : (
                        <InputNumber
                          value={val}
                          precision={0}
                          onChange={(e) => handleChange("incr_tons", e, i,'Incr Tons')}
                        />
                      )}
                    </td>
                  ))}
                </tr>
                {result.map((val) => (
                  <tr key={val.key}>
                    <td>{val.name}</td>
                    {calState.calcperyear.length > 0 ? (
                      calState.calcperyear.map((val1, i) => (
                        <td key={i}>
                          {formatNum(
                            Number(val1[val.key]).toFixed(
                              val.decimal ? val.decimal : 0
                            )
                          )}
                        </td>
                      ))
                    ) : (
                      <>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
          <Col span={14}>
            <Row gutter={15}>
              {/* <Col span={12}>
                <SpeedGraph
                  title={"Marginal FATR in Y3"}
                  currentValueText={
                    calState["marg_fatr"] !== null
                      ? `${calState["marg_fatr"]}`
                      : "-"
                  }
                  value={
                    calState["marg_fatr"] < 0
                      ? 0
                      : calState["marg_fatr"] > 5
                      ? 5
                      : Number(calState["marg_fatr"])
                  }
                  customSegmentStops={[0, 1, 2, 3, 4, 5]}
                  minValue={0}
                  maxValue={5}
                />
                <SpeedGraph
                  title={"5Y Cum EP"}
                  currentValueText={
                    calState["cum_5y_ep"]
                      ? `${calState["cum_5y_ep"]}K USD`
                      : "-"
                  }
                  value={ep < 0 ? -1 : ep > 11 ? 11 : ep}
                  customSegmentStops={[-1, 0, 2.2, 4.4, 6.6, 8.8, 11]}
                  minValue={-1}
                  maxValue={11}
                />
              </Col> */}
              <Col>
                <Row gutter={10}>
                  <Col>
                    <Thermometer_FATR value={calState["marg_fatr"]} />
                  </Col>
                  <Col>
                    <Thermometer_EP value={ep} />
                  </Col>
                  <Col>
                    <Thermometer value={calState.irr_calc} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="simulation-steps-text">
                  <h4>Color coding and threshold</h4>
                  <p>
                    <b>Marginal FATR</b> : Red below 1 as you cannot invest more
                    than your expected NSV in Year 3, Ideal target was above 4
                    so this green above 4 and even dark green above 5 but 3-4
                    (light green) remains good
                    <br />
                  </p>
                  <p>
                    <b>IRR</b> : Above 50%, project are rated green because this
                    is the minimum for savings project, Growth Project between
                    30-50% should also be considered (yellow), below 30%
                    (orange) or below 10% (red) project return is not good
                    enough
                    <br />
                  </p>
                  <p>
                    <b>5Y cumulative Economic Profit(The 5Y is coming from the sum of the Y0-Y4 + Y4/13*6 for the 5th year)</b> : This measure is
                    absolute and therefore focus only on the scale of the return
                    (IRR is balancing the view with a ratio). If cumulative EP
                    is negative, this is obvioulsly red, if this is below 1mUSD
                    this is orange, and below 2mUSD this yellow, between 2-5mUSD
                    this is light green, >5 green and > 10m this is dark green.
                    We do not recommend to calculate (cum EP)/Assets as it is
                    duplicating KPI (and creating confusion) with IRR
                    <br />
                  </p>
                </div>
                <div className="simulation-steps-text">
                  <h4>How to populate the simulator :</h4>
                  <p>
                    <b>Capital</b> : CAPEX only <br />
                    <b>Project expense</b> : OPEX only <br />
                    <b>Tax Rate</b> : local rate for Net Cash flow, please
                    consult your Finance Business Partner In case of doubt
                    please use 30% <br />
                    <b>CVSCommissioning, Validation, Start up, one off</b> :
                    cost related to the installation and the ramp up of the
                    asset <br /><br/>
                     It is recommended to input incremental revenues and costs vs a Business as Usual situation to capture the incremental value of the project
                    <br/>
                    <b>Incr GSV Year 3</b> : please input either GSV or NSV (and in
                    this case input 0% in Trade) <br />
                    <b>Trade Year 3</b> : please input the difference between
                    GSV and NSV (as % of GSV) <br />
                    <b>Incr. tons</b> : input your project assumptions and the
                    tool will use it to calculate NSV (NSV/t and Trade % assumed
                    constant). A classic ramp up could be Index 25 Y1, Index 50
                    Y2 and Index 100 Y3 with index 110-150 for the remaining
                    timing. The tool is assuming no growth beyond year 4. <br />
                    <b>Absolute Incr MCC*</b> : Please input absolute MCC(excluding depreciation) in thousands. <br />
                    <b>Note</b> : MCC* and Incr MAC*% both exclude depreciation.<br/>
                  </p>
                </div>
                <div className="simulation-steps-text">
                  <h4>Assumptions used to make the calculation quicker:</h4>
                  <p>
                    <ul>
                      <li>
                        All calculations are done in one currency only. If you
                        want to input in local currency, all results will be in
                        local currency (even if it is said USD)
                      </li>
                      <li>
                        CAB spend in first year (starting mid-year) and project
                        spend cash out spread evenly between first investment
                        period and first savings period (assumed to be just one
                        year after)
                      </li>
                      <li>Depreciation linear over 10 years</li>
                      <li>No NSV steal. No growth beyond Y4</li>
                      <li>Constant % of trade and NSV/t</li>
                      <li>
                        Absolute incremental MCC* is used at 50% for Y1 and then
                        at 100% Y2 onwards
                      </li>
                      <li>E2E LCC are considered 100% variable</li>
                      <li>A&P% kept constant across years</li>
                      <li>
                        In EP capital charge, Working capital charge considered
                        immaterial
                      </li>
                    </ul>
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </LoadingWrapper>
    </div>
  );
}
export default GrowthSimulation;