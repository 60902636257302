import {
  SUPER_USER_EDIT_YEAR_INPUT,
  SUPER_USER_RESET_STATE,
  SUPER_USER_SET_LOADING,
  SUPER_USER_SET_TABLE,
  SUPER_USER_SET_TABLE_UNSAVED_CHANGES,
} from "../actions/super_user.action";

const initailState = {
  year_column: [],
  loading: false,
  data: {},
  calculated: {},
  unsaved_changes: false,
};

export const SuperUserReducer = (state = initailState, action) => {
  switch (action.type) {
    case SUPER_USER_SET_TABLE:
      return {
        ...state,
        data: action.payload,
      };
    case SUPER_USER_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SUPER_USER_EDIT_YEAR_INPUT: {
      let data = { ...state.data };
      data[action.payload.group][action.payload.key] = action.payload.value;
      return {
        ...state,
        data,
        unsaved_changes: true,
      };
    }
    case SUPER_USER_SET_TABLE_UNSAVED_CHANGES:
      return {
        ...state,
        unsaved_changes: action.payload,
      };
    case SUPER_USER_RESET_STATE:
      return {
        ...initailState,
      };
    default:
      return state;
  }
};
