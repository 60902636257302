import React, { useEffect, useState, Suspense, lazy } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import history from "./history";
import CustomAxios from "./api.service";
import { ENDPOINTS } from "./constants";
import { useDispatch } from "react-redux";
import { USER_SET_USERNAME } from "./actions/user.action";
import SimulationContainer from "./pages/simulation";
import CompareProjectWrapper from "./pages/compare_projects";

const Login = lazy(() => import("./pages/login"));
const PowerBiDashboard = lazy(() => import("./pages/powerbi_dashboard"));
const ProjectInitiate = lazy(() => import("./pages/ProjectInitiate"));
const ProjectInput = lazy(() => import("./pages/project_input"));
const CarbonAssessment = lazy(() => import("./pages/carbon_assessment"));
const ValidatePage = lazy(() => import("./pages/validate"));
const RecommendPage = lazy(() => import("./pages/recommend"));
const SuperUserModule = lazy(() => import("./pages/super_user"));

const useAuth = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [loggedIn, setloggedIn] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [loginError, setloginError] = React.useState("");
  useEffect(() => {
    setLoading(true);

    CustomAxios(ENDPOINTS.userInfo, "GET", {})
      .then((val) => {
        setData(val.data);
        setloggedIn(true);
        setIsSuperUser(val.data.is_superuser);
        setIsStaff(val.data.is_staff)
      })
      .catch((err) => {
        if (err.response.status === 401) {
          window.location.replace(ENDPOINTS.loginpage);
          // setloginError("You do not have access to the tool");
        } else {
          setloginError("Unable to login");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return [loggedIn, loading, data, isSuperUser, isStaff,loginError];
};

function RouteConfig() {
  const dispatch = useDispatch();
  const [loggedIn, loading, data, isSuperUser,isStaff, loginError] = useAuth();
  useEffect(() => {
    dispatch({ type: USER_SET_USERNAME, payload: data });
  }, [data]);
  return (
    <Router history={history}>
      <Suspense fallback={<div>Loading...</div>}>
        {loggedIn && (
          <Switch>
            <Route path="/" exact={true} component={ProjectInitiate} />
            <Route
              path="/project_input/:id"
              exact={true}
              component={ProjectInput}
            />
            <Route
              path="/carbon_assessment/:id"
              exact={true}
              component={CarbonAssessment}
            />
            ({isStaff || isSuperUser}) ?(
              <Route
                path="/superuser"
                exact={true}
                component={SuperUserModule}
              />
            ):""
            <Route path="/validate/:id" exact={true} component={ValidatePage} />
            <Route
              path="/simulate/:id"
              exact={true}
              component={SimulationContainer}
            />
            <Route
              path="/compare/:id/:id2"
              exact={true}
              component={CompareProjectWrapper}
            />
            <Route path="/simulate" component={SimulationContainer} />
            <Route
              path="/recommend/:id"
              exact={true}
              component={RecommendPage}
            />
            <Route path="/powerbi-dashboard" exact={true}>
              <PowerBiDashboard />
            </Route>
            <Redirect to="/" />
          </Switch>
        )}
        {<Login {...{ loading, loggedIn, loginError }} />}
      </Suspense>
    </Router>
  );
}

export default RouteConfig;
