import CustomAxios from "../api.service";
import { ENDPOINTS } from "../constants";

export const EP_PROFIT_RESET_STATE = "EP_PROFIT_RESET_STATE";
export const EP_PROFIT_SET_TABLE = "EP_PROFIT_SET_TABLE";
export const EP_PROFIT_SET_LOADING = "EP_PROFIT_SET_LOADING";

export const EpProfitGetData = (body) => (dispatch) => {
  dispatch({ type: EP_PROFIT_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.epProfitRecommend, "POST", body)
    .then((val) => {
      dispatch({ type: EP_PROFIT_SET_TABLE, payload: { ...val.data } });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: EP_PROFIT_SET_LOADING, payload: false });
    });
};
