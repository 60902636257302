export const projectDetailSample = {
  project_name: "",
  project_id: "",
  short_description: "",
  ppm_id: "",
  cab_web_id: "",
  project_reference_code:"",
  model_version: "Draft",
  date_completed: ""
};
export const internalDetailSample = {
  site: "",
  segment: "",
  unit1: "",
  unit2: "",
  unit3: "",
  project_size: "",
  status: "",
  project_category: "",
  product_category: "",
  technology: "",
  brand: "",
  project_classification: "",
  earnings_method: "",
  economic_cell: "",
};
export const coreDetailSample = {
  initial_expenditure_year: "",
  initial_expenditure_period: "",
  initial_benefit_year: "",
  initial_benefit_period: "",
  local_tax_rate: "",
  salvage_value: "",
  country: "",
  mpcr_per_usd: 0,
  local_currency: "",
  confidentiality: "",
  co_authors: [],
};
