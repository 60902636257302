import React, { useRef } from "react";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { exportComponentAsPNG } from "react-component-export-image";

let line_icon = [
  <svg key="line-icon1" width="48" height="10" viewBox="0 0 48 10" fill="none">
    <line x1="5" y1="5.5" x2="43" y2="5.5" stroke="#47ADBA" strokeWidth="3" />
    <circle cx="5" cy="5" r="5" fill="#47ADBA" />
    <circle cx="43" cy="5" r="5" fill="#47ADBA" />
  </svg>,
  <svg key="line-icon2" width="48" height="10" viewBox="0 0 48 10" fill="none">
    <line x1="5" y1="5.5" x2="43" y2="5.5" stroke="#C65793" strokeWidth="3" />
    <circle cx="5" cy="5" r="5" fill="#C65793" />
    <circle cx="43" cy="5" r="5" fill="#C65793" />
  </svg>,
];

export default function CompareGraph2(props) {
  const chartRef = useRef();
  const renderLegend = (a) => {
    const { payload } = a;
    return (
      <div className="legend-container">
        <div className="line-legend">
          <div className="legend-icon">{line_icon[0]}</div>
          <div className="legend-label">{props.labels[payload[0].value]} - {props.labels.projectA}</div>
        </div>
        <div className="line-legend">
          <div className="legend-icon">{line_icon[1]}</div>
          <div className="legend-label">{props.labels[payload[1].value]} - {props.labels.projectB}</div>
        </div>
      </div>
    );
  };
  const renderTooltip = ({ active, payload, label }) => {
    return (
      <div
      className="recharts-default-tooltip"
      style={{margin: "0px", padding: '10px', backgroundColor: 'white', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap'}}
      >
        <p className="recharts-tooltip-label" style={{margin: '0px'}}>
          {label}
        </p>
        <ul
          className="recharts-tooltip-item-list"
          style={{margin: '0px', padding: '0px'}}
        >
          {payload && payload.map(val => <li
            key={val.dataKey}
            className="recharts-tooltip-item"
            style={{display: 'block', paddingTop: '4px', paddingBottom: '4px', color: val.stroke,}}
          >
            <span className="recharts-tooltip-item-name">{props.labels[val.dataKey]} - {val.dataKey.includes("2")? props.labels.projectA: props.labels.projectB}</span>
            <span className="recharts-tooltip-item-separator"> : </span>
            <span className="recharts-tooltip-item-value">{val.value}</span>
            <span className="recharts-tooltip-item-unit"></span>
          </li>)}
        </ul>
      </div>
  )};
  return (
    <div className="line-chart-container" ref={chartRef}>
      <div className="line-chart-title">
        {props.title}
        <CloudDownloadOutlined
          onClick={() =>
            exportComponentAsPNG(chartRef, { fileName: props.title })
          }
        />
      </div>
      <div className="sub-title">{props.sub_title}</div>
      <ResponsiveContainer width="99%" aspect={3}>
        <ComposedChart
          width={1200}
          height={400}
          data={props.data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="name" scale="band" />
          <YAxis />
          <Tooltip content={renderTooltip}/>
          <Legend content={renderLegend} />
          <Line type="monotone" dataKey="c_ep1" stroke="#47ADBA" />
          <Line type="monotone" dataKey="c_ep2" stroke="#C65793" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
CompareGraph2.defaultProps = {
  lines: [],
  data: [],
};
