import { message } from "antd";
import CustomAxios from "../api.service";
import { ENDPOINTS } from "../constants";

export const FATR_INPUT_RESET_STATE = "FATR_INPUT_RESET_STATE";
export const FATR_INPUT_SET_TABLE_UNSAVED_CHANGES =
  "FATR_INPUT_SET_TABLE_UNSAVED_CHANGES";
export const FATR_INPUT_SET_TABLE = "FATR_INPUT_SET_TABLE";
export const FATR_INPUT_SET_TABLE_CALCULATED =
  "FATR_INPUT_SET_TABLE_CALCULATED";
export const FATR_INPUT_SET_LOADING = "FATR_INPUT_SET_LOADING";
export const FATR_INPUT_EDIT_YEAR_INPUT = "FATR_INPUT_EDIT_YEAR_INPUT";

export const fatrInputGetData = (body) => (dispatch) => {
  dispatch({ type: FATR_INPUT_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.fatrInputGet, "POST", body)
    .then((val) => {
      dispatch({ type: FATR_INPUT_SET_TABLE, payload: { ...val.data } });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: FATR_INPUT_SET_LOADING, payload: false });
    });
};
export const fatrInputSaveData =
  (body, checkChange) => (dispatch, getState) => {
    const fatr = getState().fatrInput;
    if (checkChange && !fatr.unsaved_changes) {
      return;
    }
    dispatch({ type: FATR_INPUT_SET_LOADING, payload: true });
    CustomAxios(ENDPOINTS.fatrInputSave, "POST", { ...body, ...fatr.data })
      .then(() => {
        dispatch({
          type: FATR_INPUT_SET_TABLE_UNSAVED_CHANGES,
          payload: false,
        });
        dispatch({ type: FATR_INPUT_SET_LOADING, payload: false });
        dispatch(fatrInputGetData({ project_id: body.project_id }));
        message.success("Saved FATR Impact");
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: FATR_INPUT_SET_LOADING, payload: false });
      });
  };
