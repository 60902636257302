import { message } from "antd";
import CustomAxios from "../api.service";
import { ENDPOINTS } from "../constants";

export const PL_CALCULATION_RESET_STATE = "PL_CALCULATION_RESET_STATE";
export const PL_CALCULATION_SET_TABLE = "PL_CALCULATION_SET_TABLE";
export const PL_BA_CALCULATION_SET_TABLE = "PL_BA_CALCULATION_SET_TABLE";
export const PL_CALCULATION_SET_LOADING = "PL_CALCULATION_SET_LOADING";
export const PL_BA_CALCULATION_SET_LOADING = "PL_BA_CALCULATION_SET_LOADING";

export const PlCaluculationGetData = (body) => (dispatch) => {
  dispatch({ type: PL_CALCULATION_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.plcalculate, "POST", body)
    .then((val) => {
      dispatch({
        type: PL_CALCULATION_SET_TABLE,
        payload: { ...val.data },
      });
    })
    .catch((err) => {
      console.error(err);
      message.error("unable to load");
    })
    .finally(() => {
      dispatch({ type: PL_CALCULATION_SET_LOADING, payload: false });
    });
};

export const PlBeforeAfterGetData = (body) => (dispatch) => {
  dispatch({ type: PL_BA_CALCULATION_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.plbacalculate, "POST", body)
    .then((val) => {
      dispatch({
        type: PL_BA_CALCULATION_SET_TABLE,
        payload: { ...val.data, years_list: Object.keys(val.data.final_pl_unit_wo_brand.mac), brand_selected: val.data.brand_selected === 1 },
      });
    })
    .catch((err) => {
      console.error(err);
      message.error("unable to load");
    })
    .finally(() => {
      dispatch({ type: PL_BA_CALCULATION_SET_LOADING, payload: false });
    });
};
