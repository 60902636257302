import { message } from "antd";
import CustomAxios from "../api.service";
import { ENDPOINTS } from "../constants";

export const recalculateDataAction =
  (body, calculated) => (dispatch) => {
    CustomAxios(ENDPOINTS.recalculateData, "POST", body).then()
    .catch((err) => {
      message.error("Unable to recalculate the data for each tab which might lead to sending invalid to CAPEX Alpha",3)
      console.error(err);
    })
  };