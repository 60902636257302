import apiService from "../api.service";
import { message } from "antd";
import { ENDPOINTS } from "../constants";
export const INITIATE_PROJECT_SET_LIST_PROJECTS =
  "INITIATE_PROJECT_SET_LIST_PROJECTS";
export const INITIATE_PROJECT_SET_LOADING = "INITIATE_PROJECT_SET_LOADING";
export const INITIATE_PROJECT_SET_SELECTED_PROJECT =
  "INITIATE_PROJECT_SET_SELECTED_PROJECT";
export const INITIATE_PROJECT_SET_SELECTED_PRODUCT =
  "INITIATE_PROJECT_SET_SELECTED_PRODUCT";
export const INITIATE_PROJECT_SET_SEARCH = "INITIATE_PROJECT_SET_SEARCH";
export const INITIATE_PROJECT_DELETE_PROJECT =
  "INITIATE_PROJECT_DELETE_PROJECT";
export const INITIATE_PROJECT_FAV_PROJECT = "INITIATE_PROJECT_FAV_PROJECT";

export const PROJECT_CREATION_SET_DROPDOWN_OPTIONS =
  "PROJECT_CREATION_GET_DROPDOWN_OPTIONS";

export const INITIATE_PROJECT_CAPEX_IDEA_CODE =
  "INITIATE_PROJECT_CAPEX_IDEA_CODE";

export const projectCreationGetDropdown = () => (dispatch) => {
  apiService(ENDPOINTS.projectDropdown)
    .then((val) => {
      let d = { ...val.data };
      d.gbu = Object.keys(val.data.unit_hierarchy);
      dispatch({ type: PROJECT_CREATION_SET_DROPDOWN_OPTIONS, payload: d });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      // code for finally
    });
};

export const getProjectListData = () => (dispatch) => {
  dispatch({ type: INITIATE_PROJECT_SET_LOADING, payload: true });
  apiService(ENDPOINTS.listProjects)
    .then((val) => {
      dispatch({ type: INITIATE_PROJECT_SET_LIST_PROJECTS, payload: val.data });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: INITIATE_PROJECT_SET_LOADING, payload: false });
    });
};
export const indeleteProject = (id) => (dispatch) => {
  dispatch({ type: INITIATE_PROJECT_SET_LOADING, payload: true });
  apiService(ENDPOINTS.deleteProject, "POST", { project_id: id })
    .then(() => {
      apiService(ENDPOINTS.projectDropdown)
      .then((val) => {
                      let d = { ...val.data };
                      d.gbu = Object.keys(val.data.unit_hierarchy);
                      dispatch({ type: PROJECT_CREATION_SET_DROPDOWN_OPTIONS, payload: d });
                    })
                      .catch((err) => {
                        console.error(err);
                      })
                      .finally(() => {
                        // code for finally
                      });
                      dispatch({ type: INITIATE_PROJECT_DELETE_PROJECT, payload: id });
    })
    .catch((err) => {
      if(err.response.data=="Project status is not Draft")
      {
        message.warning("The project cannot be deleted as the status of the project is not draft",3)
      }
      else
      {
      message.error(err.response.data,2)
      }
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: INITIATE_PROJECT_SET_LOADING, payload: false });
    });
};
export const inFavProject = (id, bookmarked) => (dispatch) => {
  dispatch({ type: INITIATE_PROJECT_SET_LOADING, payload: true });
  apiService(ENDPOINTS.bookmarkProject, "POST", { project_id: id, bookmarked })
    .then(() => {
      dispatch({ type: INITIATE_PROJECT_FAV_PROJECT, payload: id });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: INITIATE_PROJECT_SET_LOADING, payload: false });
    });
};

export const saveCapexIdeaCode = (capexCode) => (dispatch) => {
  dispatch({ type: INITIATE_PROJECT_CAPEX_IDEA_CODE, payload: capexCode });
}
