import axios from "axios";
import { ENDPOINTS } from "./constants";
let numberOfAjaxCAllPending = 0;

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    numberOfAjaxCAllPending++;
    // show loader
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    numberOfAjaxCAllPending--;
    if (numberOfAjaxCAllPending === 0) {
      //hide loader
    }
    return response;
  },
  function (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      if (error.response.data && error.response.data.code === "ACCESS_DENIED") {
        // pass
      } else {
        window.location.replace(ENDPOINTS.loginpage);
        // window.location.href = '/';
        // localStorage.clear();
        // authContext.login();
      }
    }
    return Promise.reject(error);
  }
);
function CustomAxios(url, method, body, headers = {}) {
  return axios({
    url,
    method,
    data: body,
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${getToken()}`,
      ...headers,
    },
    withCredentials: true,
  });
}
export default CustomAxios;
