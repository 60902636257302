import {
  INITIATE_PROJECT_DELETE_PROJECT,
  INITIATE_PROJECT_FAV_PROJECT,
  INITIATE_PROJECT_SET_LIST_PROJECTS,
  INITIATE_PROJECT_SET_LOADING,
  INITIATE_PROJECT_SET_SEARCH,
  INITIATE_PROJECT_SET_SELECTED_PRODUCT,
  INITIATE_PROJECT_SET_SELECTED_PROJECT,
  PROJECT_CREATION_SET_DROPDOWN_OPTIONS,
  INITIATE_PROJECT_CAPEX_IDEA_CODE
} from "../actions/initiateProject.action";
export const initailState = {
  project_list: [],
  loading: false,
  project_size_option: [],
  project_category_option: [],
  selected_project_size: "",
  selected_product_category: "",
  search_text: "",

  //dropdown options

  project_size: [],
  project_category: [],
  earnings_method: [],
  model_version: [],
  initial_expenditure_period: [],
  working_capital_growth: [],
  initial_benefit_period: [],
  initial_expenditure_year: [],
  initial_benefit_year: [],
  gbu: [],
  region: [],
  unit: [],
  business_segment: [],
  product_category: [],
  brand: [],
  economic_cell: [],
  unit_hierarchy: {},
  capex__hierarchy: [],
  product_hierarchy: {},
  currency_mapping: {},
  ppm_mapping: {},
  userlist: [],
  site_mapping: {},
  capex_alpha_idea_code: 0
};

export const InitiateProject = (state = initailState, action) => {
  switch (action.type) {
    case INITIATE_PROJECT_SET_LIST_PROJECTS: {
      let project_size_option = Array.from(
        new Set(action.payload.map((val) => val.project_size))
      );
      let project_category_option = Array.from(
        new Set(action.payload.map((val) => val.project_category))
      );
      return {
        ...state,
        project_list: action.payload,
        project_size_option,
        project_category_option,
        selected_product_size: project_size_option[0],
        selected_project_category: project_category_option[0],
      };
    }
    case INITIATE_PROJECT_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case PROJECT_CREATION_SET_DROPDOWN_OPTIONS:
      return {
        ...state,
        ...action.payload,
      };
    case INITIATE_PROJECT_SET_SELECTED_PROJECT:
      return {
        ...state,
        selected_project_category: action.payload,
      };
    case INITIATE_PROJECT_SET_SELECTED_PRODUCT:
      return {
        ...state,
        selected_product_category: action.payload,
      };
    case INITIATE_PROJECT_SET_SEARCH:
      return {
        ...state,
        search_text: action.payload,
      };
    case INITIATE_PROJECT_DELETE_PROJECT:
      return {
        ...state,
        project_list: state.project_list.filter(
          (val) => val.project_id !== action.payload
        ),
      };
    case INITIATE_PROJECT_FAV_PROJECT:
      return {
        ...state,
        project_list: state.project_list.map((val) => {
          if (action.payload === val.project_id) {
            return { ...val, bookmarked: !val.bookmarked };
          }
          return val;
        }),
      };
    case INITIATE_PROJECT_CAPEX_IDEA_CODE:
      return {
        ...state,
        capex_alpha_idea_code: action.payload,
      };
    default:
      return state;
  }
};
