import React, { useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Wrapper from "../../components/wrapper";
import SideNavList from "../../components/side_nav_list";
import { useDecodedParams } from "../../utils/param";
import CompareProject from "./compare_ui";
function CompareProjectWrapper() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabCollapsed, setTabCollapsed] = useState(false);
  const { id } = useDecodedParams();

  const tabsList = [
    {
      name: "Compare Projects",
      component: <CompareProject projectDetail={{}} />,
      icon: <svg viewBox="64 64 896 896" focusable="false" data-icon="column-width" width="18px" height="18px" fill="currentColor" aria-hidden="true"><path d="M180 176h-60c-4.4 0-8 3.6-8 8v656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V184c0-4.4-3.6-8-8-8zm724 0h-60c-4.4 0-8 3.6-8 8v656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V184c0-4.4-3.6-8-8-8zM785.3 504.3L657.7 403.6a7.23 7.23 0 00-11.7 5.7V476H378v-62.8c0-6-7-9.4-11.7-5.7L238.7 508.3a7.14 7.14 0 000 11.3l127.5 100.8c4.7 3.7 11.7.4 11.7-5.7V548h268v62.8c0 6 7 9.4 11.7 5.7l127.5-100.8c3.8-2.9 3.8-8.5.2-11.4z"></path></svg>,
    }
  ];

  function handleCollapse(e) {
    e.stopPropagation();
    setTabCollapsed(!tabCollapsed);
  }
  return (
    <Wrapper styles={{}}>
      <div className="project-input-page">
        <SideNavList
          showInputNAv={false}
          id={id}
          page_id={0}
          tabCollapsed={tabCollapsed}
          tabsList={tabsList}
          setSelectedTabIndex={setSelectedTabIndex}
          selectedTabIndex={selectedTabIndex}
          handleCollapse={handleCollapse}
        />
        {
          <div className="project-right-content">
            {tabsList[selectedTabIndex].component}
            <div className="bottom-navigation-row">
              {selectedTabIndex !== 0 && (
                <Button
                  onClick={() => setSelectedTabIndex(selectedTabIndex - 1)}
                  type="ghost"
                  icon={<LeftOutlined />}
                >
                  {tabsList[selectedTabIndex - 1].name}
                </Button>
              )}
              {selectedTabIndex < tabsList.length - 1 && (
                <Button
                  onClick={() => setSelectedTabIndex(selectedTabIndex + 1)}
                  className="next-button"
                  type="ghost"
                  icon={<RightOutlined />}
                >
                  {tabsList[selectedTabIndex + 1].name}
                </Button>
              )}
            </div>
          </div>
        }
      </div>
    </Wrapper>
  );
}
export default CompareProjectWrapper;
