import {
  TIMING_EXPENDITURE_EDIT_YEAR_INPUT,
  TIMING_EXPENDITURE_SET_TABLE_UNSAVED_CHANGES,
  TIMING_EXPENDITURE_SET_LOADING,
  TIMING_EXPENDITURE_SET_TABLE,
  TIMING_EXPENDITURE_SET_TABLE_CALCULATED,
  TIMING_EXPENDITURE_RESET_STATE,
  TIMING_EXPENDITURE_SET_RENAME,
} from "../actions/timingExpenditure.action";

const initailState = {
  year_column: [],
  loading: false,
  data: {},
  calculated: {},
  unsaved_changes: false,
};

export const TimingExpenditureinputReducer = (state = initailState, action) => {
  switch (action.type) {
    case TIMING_EXPENDITURE_SET_TABLE: {
      let d = action.payload;
      if (d.years && d.project_id) {
        let year = [...d.years];
        delete d.years;
        delete d.project_id;
        return {
          ...state,
          data: d,
          year_column: year,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case TIMING_EXPENDITURE_SET_TABLE_CALCULATED:
      return {
        ...state,
        data: {
          ...state.data,
          total_capital: action.payload.total_capital,
          total_capital_expense: action.payload.total_capital_expense,
          total_expense: action.payload.total_expense,
        },
      };
    case TIMING_EXPENDITURE_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case TIMING_EXPENDITURE_EDIT_YEAR_INPUT: {
      let data = { ...state.data };
      data[action.payload.type][action.payload.year][action.payload.qr] =
        action.payload.value;
      return {
        ...state,
        data,
        unsaved_changes: true,
      };
    }
    case TIMING_EXPENDITURE_SET_TABLE_UNSAVED_CHANGES:
      return {
        ...state,
        unsaved_changes: action.payload,
      };
    case TIMING_EXPENDITURE_RESET_STATE:
      return {
        ...initailState,
      };
    case TIMING_EXPENDITURE_SET_RENAME: {
      let data3 = { ...state.data };
      data3[action.payload.type + "_description"][
        action.payload.key
      ].desc_name = action.payload.value;
      return {
        ...state,
        data: data3,
        unsaved_changes: true,
      };
    }
    default:
      return state;
  }
};
