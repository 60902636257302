import React from "react";
import { QueryClientProvider, QueryClient } from 'react-query';
import RouteConfig from "./routes";

import "./assets/styles/app.less";
import "./assets/styles/style.scss";

const queryClient = new QueryClient();

function App() {
    return(
        <QueryClientProvider client={queryClient}>
          <RouteConfig />
        </QueryClientProvider>
    );
}

export default App;
