import {
  DEPRECIATION_EDIT_TOGGLE_INPUT,
  DEPRECIATION_EDIT_YEAR_INPUT,
  DEPRECIATION_RESET_STATE,
  DEPRECIATION_SET_LOADING,
  DEPRECIATION_SET_TABLE,
  DEPRECIATION_SET_TABLE_UNSAVED_CHANGES,
} from "../actions/depreciation.action";

const initailState = {
  year_column: [],
  loading: false,
  data: {},
  unsaved_changes: false,
};

export const DepriciationReducer = (state = initailState, action) => {
  switch (action.type) {
    case DEPRECIATION_SET_TABLE: {
      let d = action.payload;
      if (d.years && d.project_id) {
        let year = [...d.years];
        delete d.years;
        delete d.project_id;
        return {
          ...state,
          data: d,
          year_column: year,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case DEPRECIATION_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case DEPRECIATION_EDIT_YEAR_INPUT: {
      let data = { ...state.data };
      data[action.payload.group][action.payload.key][action.payload.year] =
        action.payload.value;
      return {
        ...state,
        data,
        unsaved_changes: true,
      };
    }
    case DEPRECIATION_EDIT_TOGGLE_INPUT:
      return {
        ...state,
        data: {
          ...state.data,
          toggle_input: action.payload,
        },
        unsaved_changes: true,
      };
    case DEPRECIATION_SET_TABLE_UNSAVED_CHANGES:
      return {
        ...state,
        unsaved_changes: action.payload,
      };
    case DEPRECIATION_RESET_STATE:
      return {
        ...initailState,
      };
    default:
      return state;
  }
};
