import { SET_FOOTPRINT_EXISTING_DATA, SET_FOOTPRINT_DROPDOWN_DATA, SET_FOOTPRINT_THERMAL_DATA, SET_FOOTPRINT_ELECTRICAL_DATA, SET_INCREMENTAL_CONSUMPTION_DATA, SET_ENERGY_INPUT_DATA, SET_INCREMENTAL_TRANSPORT_DATA, SET_CALCULATED_TRANSPORT_DATA, SET_RECIPE_FOOTPRINT_DATA, SET_PACKAGING_FOOTPRINT_DATA, SET_FINAL_FOOTPRINT_DATA, SET_LINEITEM_VOLUME_DATA, SET_CARBON_FOOTPRINT_DATA, CLEAR_CARBON_FOOTPRINT_DATA } from "../../actions/carbon_footprint/types";

const initialState = {
  type: "Growth",
  fossil_thermal_energy: {},
  fossil_electrical_energy: {},
  fossil_nrg_intensity: {},
  line_item: [],
  incremental_energy_consumption: [],
  input_incremental_Transport: [],
  calculated_incremental_transport: [],
  recipe_footprint: [],
  packaging_footprint: [],
  final_footprint: {},
  updated: false
}


const carbonFootprintReducer = function (state = initialState, action = {}) {
  
    switch (action.type) {
      
      case SET_FOOTPRINT_EXISTING_DATA:
        let newDataObject = Object.assign(state.line_item, {...action.payload});
        return {
          ...state,
         ...newDataObject
        };
      case SET_FOOTPRINT_DROPDOWN_DATA:
        return {
          ...state,
          fossil_thermal_energy: action.payload.fossil_thermal_energy,
          fossil_electrical_energy: action.payload.fossil_electrical_energy,
          fossil_nrg_intensity: action.payload.fossil_nrg_intensity
        };
      case SET_FOOTPRINT_THERMAL_DATA:
        
        return {
          ...state,
          fossil_thermal_energy: action.payload
        };
      case SET_FOOTPRINT_ELECTRICAL_DATA:
        
        return {
          ...state,
          fossil_electrical_energy: action.payload.fossil_electrical_energy,
          fossil_nrg_intensity: action.payload.fossil_nrg_intensity,
        };
      case SET_INCREMENTAL_CONSUMPTION_DATA:
        
        return {
          ...state,
          incremental_energy_consumption: action.payload
        };
      case SET_ENERGY_INPUT_DATA:
        
        return {
          ...state,
          fossil_nrg_intensity: action.payload.fossil_energy,
          incremental_energy_consumption: action.payload.incremental_energy
        };
      case SET_INCREMENTAL_TRANSPORT_DATA:
        
        return {
          ...state,
          input_incremental_Transport: action.payload,
        };
      case SET_CALCULATED_TRANSPORT_DATA:
        
        return {
          ...state,
          calculated_incremental_transport: action.payload,
        };
      case SET_RECIPE_FOOTPRINT_DATA:
        
        return {
          ...state,
          recipe_footprint: action.payload,
        };
      case SET_PACKAGING_FOOTPRINT_DATA:
        
        return {
          ...state,
          packaging_footprint: action.payload,
        };
      case SET_FINAL_FOOTPRINT_DATA:
        let footprintData = Object.assign(state.final_footprint, {...action.payload});
        return {
          ...state,
          ...footprintData,
        };
      case SET_LINEITEM_VOLUME_DATA:
        
        return {
          ...state,
          line_item: action.payload.lineItem,
          recipe_footprint: action.payload.recipeItem,
          packaging_footprint: action.payload.packagingItem,
        };
        case SET_CARBON_FOOTPRINT_DATA:
          let finalData = Object.assign(state, {...action.payload});
        return {
          ...state,
          ...finalData
        };
        case CLEAR_CARBON_FOOTPRINT_DATA:
        return {
          ...state,
          ...initialState
        };
      default:
        return state;
    }
  };

  export default carbonFootprintReducer;