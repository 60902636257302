import {
  VOLUME_ASSUMPTION_ADD_TAB,
  VOLUME_ASSUMPTION_EDIT_YEAR_INPUT,
  VOLUME_ASSUMPTION_REMOVE_TAB,
  VOLUME_ASSUMPTION_RENAME_TAB,
  VOLUME_ASSUMPTION_RESET_STATE,
  VOLUME_ASSUMPTION_SET_LOADING,
  VOLUME_ASSUMPTION_SET_SUMMARY,
  VOLUME_ASSUMPTION_SET_TABS,
  VOLUME_ASSUMPTION_SET_UNSAVED_CHANGES,
} from "../actions/volumeAssumption.action";

const initailState = {
  year_column: [],
  loading: false,
  volume_assumption: [],
  volume_assumption_summary: {
    volume: {},
    gsv: {},
    gsv_volume: {},
  },
  unsaved_changes: false,
};

export const VolumeAssumptionReducer = (state = initailState, action) => {
  switch (action.type) {
    case VOLUME_ASSUMPTION_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case VOLUME_ASSUMPTION_SET_SUMMARY:
      return {
        ...state,
        volume_assumption_summary: action.payload,
      };
    case VOLUME_ASSUMPTION_ADD_TAB:
      return {
        ...state,
        volume_assumption: [...state.volume_assumption, action.payload],
      };
    case VOLUME_ASSUMPTION_REMOVE_TAB: {
      let volume_assumption = JSON.parse(
        JSON.stringify(state.volume_assumption)
      );
      volume_assumption.splice(action.payload, 1);
      return {
        ...state,
        volume_assumption,
      };
    }
    case VOLUME_ASSUMPTION_EDIT_YEAR_INPUT: {
      let loc = action.payload.loc;
      let volume_assumption1 = [...state.volume_assumption];
      volume_assumption1[loc][action.payload.key][action.payload.year] =
        action.payload.value;
      return {
        ...state,
        volume_assumption: volume_assumption1,
        unsaved_changes: true,
      };
    }
    case VOLUME_ASSUMPTION_SET_TABS:
      return {
        ...state,
        volume_assumption: action.payload.item,
        year_column: action.payload.years,
      };
    case VOLUME_ASSUMPTION_RENAME_TAB: {
      let tabIndex = action.payload.index;
      let volume_assumption2 = [...state.volume_assumption];
      volume_assumption2[tabIndex].item_name = action.payload.name;
      return {
        ...state,
        volume_assumption: volume_assumption2,
        unsaved_changes: true,
      };
    }
    case VOLUME_ASSUMPTION_SET_UNSAVED_CHANGES:
      return {
        ...state,
        unsaved_changes: action.payload,
      };
    case VOLUME_ASSUMPTION_RESET_STATE:
      return {
        ...initailState,
      };
    default:
      return state;
  }
};
