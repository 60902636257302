import { message } from "antd";
import CustomAxios from "../api.service";
import { ENDPOINTS } from "../constants";

export const BENEFITS_INPUT_RESET_STATE = "BENEFITS_INPUT_RESET_STATE";
export const BENEFITS_INPUT_RENAME_DESC = "BENEFITS_INPUT_RENAME_DESC";
export const BENEFITS_INPUT_SET_TABLE_UNSAVED_CHANGES =
  "BENEFITS_INPUT_SET_TABLE_UNSAVED_CHANGES";
export const BENEFITS_INPUT_SET_DELETE_ITEM = "BENEFITS_INPUT_SET_DELETE_ITEM";
export const BENEFITS_INPUT_SET_ADD_ITEM = "BENEFITS_INPUT_SET_ADD_ITEM";
export const BENEFITS_INPUT_SET_TABLE = "BENEFITS_INPUT_SET_TABLE";
export const BENEFITS_INPUT_SET_TABLE_CALCULATED =
  "BENEFITS_INPUT_SET_TABLE_CALCULATED";
export const BENEFITS_INPUT_SET_LOADING = "BENEFITS_INPUT_SET_LOADING";
export const BENEFITS_INPUT_EDIT_YEAR_INPUT = "BENEFITS_INPUT_EDIT_YEAR_INPUT";

export const benefitsInputAddItem = (param) => (dispatch, getState) => {
  const benefitsIn = getState().benefitsInput;
  let description = {
    item_name: param.name,
  };
  let body = {
    item: [
      {
        item_name: param.name,
      },
    ],
    ...param,
  };
  dispatch({ type: BENEFITS_INPUT_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.benefitsInputAdd, "POST", body)
    .then((val) => {
      description.item_id = val.data.item_id;
      let value = {};
      benefitsIn.year_column.forEach((itm) => {
        value[itm] = null;
      });
      description.value = value;
      dispatch({
        type: BENEFITS_INPUT_SET_ADD_ITEM,
        payload: {
          item: description,
          type: param.type,
        },
      });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: BENEFITS_INPUT_SET_LOADING, payload: false });
    });
};
export const benefitsInputGetData = (body, calculated) => (dispatch) => {
  dispatch({ type: BENEFITS_INPUT_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.benefitsInputGet, "POST", body)
    .then((val) => {
      dispatch({
        type: calculated
          ? BENEFITS_INPUT_SET_TABLE_CALCULATED
          : BENEFITS_INPUT_SET_TABLE,
        payload: { ...val.data },
      });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: BENEFITS_INPUT_SET_LOADING, payload: false });
    });
};
export const benefitsInputSaveData =
  (body, checkChange) => (dispatch, getState) => {
    const benefitsInput = getState().benefitsInput;
    if (checkChange && !benefitsInput.unsaved_changes) {
      return;
    }
    body["benefits"] = benefitsInput.data["benefits"];
    body["costs"] = benefitsInput.data["costs"];
    body["volumn"] = benefitsInput.data["volumn"];
    dispatch({ type: BENEFITS_INPUT_SET_LOADING, payload: true });
    CustomAxios(ENDPOINTS.benefitsInputSave, "POST", { ...body })
      .then(() => {
        message.success("Saved Benefits");
        dispatch(benefitsInputGetData({ project_id: body.project_id }));
        dispatch({
          type: BENEFITS_INPUT_SET_TABLE_UNSAVED_CHANGES,
          payload: false,
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch({ type: BENEFITS_INPUT_SET_LOADING, payload: false });
      });
  };
export const benefitsInputDeleteData = (body, index) => (dispatch) => {
  dispatch({ type: BENEFITS_INPUT_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.benefitsInputDelete, "POST", { ...body })
    .then(() => {
      dispatch({
        type: BENEFITS_INPUT_SET_DELETE_ITEM,
        payload: { ...body, index },
      });
      message.success("Deleted Successfully");
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: BENEFITS_INPUT_SET_LOADING, payload: false });
    });
};
