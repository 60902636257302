import { message } from "antd";
import CustomAxios from "../api.service";
import { ENDPOINTS } from "../constants";

export const SUMMARY_RESET_STATE = "SUMMARY_RESET_STATE";
export const SUMMARY_SET_TABLE = "SUMMARY_SET_TABLE";
export const SUMMARY_SET_LOADING = "SUMMARY_SET_LOADING";
export const SUMMARY_SET_PROJECT_OVERVIEW = "SUMMARY_SET_PROJECT_OVERVIEW";
export const SUMMARY_SET_PROJECT_OVERVIEW_CHANGE_INPUT =
  "SUMMARY_SET_PROJECT_OVERVIEW_CHANGE_INPUT";
export const SUMMARY_SET_UNSAVED_CHANGES_OVERVIEW =
  "SUMMARY_SET_UNSAVED_CHANGES_OVERVIEW";
export const SUMMARY_SET_LOADING_OVERVIEW = "SUMMARY_SET_LOADING_OVERVIEW";

export const SummarySaveOverview = (body) => (dispatch) => {
  dispatch({ type: SUMMARY_SET_LOADING_OVERVIEW, payload: true });
  CustomAxios(ENDPOINTS.summarySaveOverview, "POST", body)
    .then(() => {
      message.success("Save Successfully");
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: SUMMARY_SET_LOADING_OVERVIEW, payload: false });
      dispatch({ type: SUMMARY_SET_UNSAVED_CHANGES_OVERVIEW, payload: false });
    });
};

export const SummaryGetData = (body) => (dispatch) => {
  dispatch({ type: SUMMARY_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.summaryRecommend, "POST", body)
    .then((val) => {
      dispatch({ type: SUMMARY_SET_TABLE, payload: { ...val.data, ...body } });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: SUMMARY_SET_LOADING, payload: false });
    });
};
export const SummaryGetOverview = (body) => (dispatch) => {
  dispatch({ type: SUMMARY_SET_LOADING_OVERVIEW, payload: true });
  CustomAxios(ENDPOINTS.summaryOverview, "POST", body)
    .then((val) => {
      dispatch({
        type: SUMMARY_SET_PROJECT_OVERVIEW,
        payload: { ...val.data, ...body },
      });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: SUMMARY_SET_LOADING_OVERVIEW, payload: false });
    });
};
