import CustomAxios from "../api.service";
import { ENDPOINTS } from "../constants";

export const SENSITIVITIES_RESET_STATE = "SENSITIVITIES_RESET_STATE";
export const SENSITIVITIES_SET_TABLE = "SENSITIVITIES_SET_TABLE";
export const SENSITIVITIES_SET_INPUT_DATA = "SENSITIVITIES_SET_INPUT_DATA";
export const SENSITIVITIES_SET_LOADING_calculate =
  "SENSITIVITIES_SET_LOADING_calculate";
export const SENSITIVITIES_SET_LOADING = "SENSITIVITIES_SET_LOADING";
export const SENSITIVITIES_CHANGE_INPUT = "SENSITIVITIES_CHANGE_INPUT";
export const SENSITIVITIES_UNSAVED_CHANGES = "SENSITIVITIES_UNSAVED_CHANGES";

export const SensitivitiesCalculate =
  (body, tables = []) =>
  (dispatch) => {
    dispatch({ type: SENSITIVITIES_SET_LOADING_calculate, payload: true });
    if (tables.includes("sensitivities_faa_vol")) {
      tables.push("sensitivities_faa_vol_ep");
    }
    if (tables.includes("sensitivities_faa_mac")) {
      tables.push("sensitivities_faa_mac_ep");
    }
    if (tables.includes("sensitivities_faa_earnings")) {
      tables.push("sensitivities_faa_earnings_ep");
    }
    let keys = {
      sensitivities_faa_vol: ENDPOINTS.sensitivitesCalculateVol,
      sensitivities_faa_mac: ENDPOINTS.sensitivitesCalculateMac,
      sensitivities_faa_vol_ep: ENDPOINTS.sensitivitesCalculateVolEP,
      sensitivities_faa_mac_ep: ENDPOINTS.sensitivitesCalculateMacEP,
      sensitivities_faa_earnings: ENDPOINTS.sensitivitesCalculateEarnings,
      sensitivities_faa_earnings_ep: ENDPOINTS.sensitivitesCalculateEarningsEP,
    };
    Promise.allSettled(
      tables.map((val) =>
        CustomAxios(keys[val], "POST", body).then((val5) => {
          if (val.includes("_ep")) {
            let result = {};
            for (let x in val5.data) {
              result[x + "_ep"] = val5.data[x];
            }
            return result;
          }
          return val5.data;
        })
      )
    )
      .then((val) => {
        let data = val
          .filter((val1) => val1.status === "fulfilled")
          .map((val2) => val2.value)
          .reduce(
            (acc, val3) => {
              return {
                ...acc,
                ...val3,
              };
            },
            {
              faa_vol_per: [],
              faa_mac_per: [],
              mac_per: [],
              vol_per: [],
              earnings_per: [],
              faa_earnings_per: [],

              faa_mac_list: [],
              faa_vol_list: [],
              mac_faa_matrix: [],
              mac_list: [],
              vol_faa_matrix: [],
              vol_list: [],

              faa_mac_list_ep: [],
              faa_vol_list_ep: [],
              mac_faa_matrix_ep: [],
              mac_list_ep: [],
              vol_faa_matrix_ep: [],
              vol_list_ep: [],

              earnings_faa_matrix: [],
              earnings_list: [],
              faa_earnings_list: [],

              earnings_faa_matrix_ep: [],
              earnings_list_ep: [],
              faa_earnings_list_ep: [],
            }
          );
        dispatch({ type: SENSITIVITIES_SET_TABLE, payload: data });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch({ type: SENSITIVITIES_SET_LOADING_calculate, payload: false });
      });
  };
export const SensitivitiesSaveInput =
  (body, checkChange, tables) => (dispatch, getState) => {
    const sense = getState().sensitivites;
    if (checkChange && !sense.unsaved_changes) {
      return;
    }
    body.vol_per = sense.vol_per;
    body.mac_per = sense.mac_per;
    body.faa_vol_per = sense.faa_vol_per;
    body.faa_mac_per = sense.faa_mac_per;
    body.earnings_per = sense.earnings_per;
    body.faa_earnings_per = sense.faa_earnings_per;
    dispatch({ type: SENSITIVITIES_SET_LOADING, payload: true });
    CustomAxios(ENDPOINTS.sensitivitessaveData, "POST", body)
      .then(() => {
        dispatch(
          SensitivitiesCalculate({ project_id: body.project_id }, tables)
        );
        dispatch({ type: SENSITIVITIES_UNSAVED_CHANGES, payload: false });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch({ type: SENSITIVITIES_SET_LOADING, payload: false });
      });
  };
export const SensitivitiesGetInput = (body) => (dispatch) => {
  dispatch({ type: SENSITIVITIES_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.sensitivitesgetDate, "POST", body)
    .then((val) => {
      dispatch({
        type: SENSITIVITIES_SET_INPUT_DATA,
        payload: { ...val.data },
      });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: SENSITIVITIES_SET_LOADING, payload: false });
    });
};
