export function sumAr(arr, def = "", decimal = 0) {
  let summed = Number(
    sum(arr.map((val) => (val ? parseFloat(val) : 0)))
  ).toFixed(decimal);
  return isNaN(summed) ? def : Number(summed).toLocaleString();
}
export function sum(arr) {
  return arr.map((val) => Number(val)).reduce((acc, val) => acc + val, 0);
}
export function cloneDeep(obj) {
  return JSON.parse(JSON.stringify(obj));
}
export function formatNum(num, suffix = "", limit) {
  if (num === "N/A") {
    return num;
  }
  if (num !== null) {
    return `${Number(num).toLocaleString(undefined, limit ? {minimumFractionDigits: limit}: {})}` + suffix;
  }
  return num;
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

