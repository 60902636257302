import { Card } from "antd";
import React from "react";
import CustomAxios from "../../api.service";
import { ENDPOINTS } from "../../constants";
import { formatNum, sumAr } from "../../utils/mathFn";
import { useDecodedParams } from "../../utils/param";
import CompareChart1 from "./compare_graph_1";
import CompareGraph2 from "./compare_graph_2";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { exportComponentAsPNG } from "react-component-export-image";
import LoadingWrapper from "../../components/loading_wrapper";
function ResultProject({ title, data }) {
  let cardRef = React.useRef();

  return (
    <div className="line-chart-container">
      <div className="line-chart-title">
        {title}
        <CloudDownloadOutlined
          onClick={() => exportComponentAsPNG(cardRef, { fileName: title })}
        />
      </div>
      <div
        className="card-export-area"
        ref={cardRef}
        style={{ padding: "10px" }}
      >
        <div className="cards-row-2">
          <div className="cards-row-title-2">Inputs/Investments</div>
          <div className="cards-list">
            {[
              {
                value: data.total_capital,
                title: "Total Capital",
              },
              {
                value: data.project_expenses,
                title: "Project Expenses",
              },
              {
                value: data.amount_requested,
                title: "Amount Requested",
              },
              {
                value: data.fixed_asset_retirements,
                title: "Fixed Assest Retirements",
              },
              {
                value: data.net_fixed_assets,
                title: "Net Fixed Assests Added",
              },
            ].map((val, key) => (
              <div className="r-card-container" key={key}>
                <Card className="kpi-card-2">
                  <div className="kpi-number">
                    {val.value ? formatNum(val.value) : "-"}
                  </div>
                  <div className="kpi-title">{val.title}</div>
                </Card>
              </div>
            ))}
          </div>
        </div>
        <div className="cards-row-2">
          <div className="cards-row-title-2">
            Output/Critical Success Factors
          </div>
          <div className="cards-list">
            {[
              {
                value: data.base_case_IRR,
                title: "Base Case IRR",
              },
              {
                value: data.payback_years,
                title: "Payback Years",
              },
              {
                value: data.payback_periods,
                title: "Payback Periods",
              },
              {
                value: data.incremental_nsv,
                title: "Incremental NSV",
                info: "in Year 3",
              },
              {
                value: data.incremental_fatr,
                title: "Incremental FATR",
                info: "in Year 3",
              },
              {
                value: data["10_year_cf"],
                title: "10 Year Cash Flow",
              },
              {
                value: data["5_year_cf"],
                title: "5 Year Cash Flow",
              },
              {
                value: data["5_year_ep"],
                title: "5 Year EP",
              },
            ].map((val, key) => (
              <div className="r-card-container" key={key}>
                <Card className="kpi-card-2">
                  <div className="kpi-number">
                    {val.value
                      ? formatNum(val.value, key === 0 ? "%" : "")
                      : "-"}
                  </div>
                  <div className="kpi-title">
                    {val.title + ` ${val.info ? val.info : ""}`}
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </div>
        <div className="cards-row-2">
          <div className="cards-row-title-2">Net Present Value (NPV)</div>
          <div className="cards-list">
            {[
              {
                value: data["1year_npv"],
                title: "1 Year NPV",
              },
              {
                value: data["5year_npv"],
                title: "5 Year NPV",
              },
              {
                value: data["10_year_npv"],
                title: "10 Year NPV",
              },
              {
                value: data.probability_index,
                title: "Profitability Index",
                info: "Total NPV/Amount Requested",
              },
            ].map((val, key) => (
              <div className="r-card-container" key={key}>
                <Card className="kpi-card-2">
                  <div className="kpi-number">
                    {val.value && !["N/A", "-"].includes(val.value)
                      ? formatNum(val.value)
                      : "-"}
                  </div>
                  <div className="kpi-title">{val.title}</div>
                  <div className="kpi-title">{val.info}</div>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function CompareProject() {
  const { id, id2 } = useDecodedParams();
  const [projectA, setProjectA] = React.useState("");
  const [projectB, setProjectB] = React.useState("");
  const [graph1, setgraph1] = React.useState([]);
  const [graph2, setgraph2] = React.useState([]);
  const [result1, setresult1] = React.useState({});
  const [result2, setresult2] = React.useState({});
  const [loading1, setloading1] = React.useState(false);
  const [loading2, setloading2] = React.useState(false);
  const labels = {
    nopat1: "Incremental NOPAT (Profit - Tax)",
    nopat2: "Incremental NOPAT (Profit - Tax)",
    ep1: "Economic Profit (NOPAT - Capital Charge)",
    ep2: "Economic Profit (NOPAT - Capital Charge)",
    projectA: "",
    projectB: "",
  };
  const labels2 = {
    projectA: "",
    projectB: "",
    c_ep1: "Cumulative EP",
    c_ep2: "Cumulative EP",
  };
  React.useEffect(() => {
    setloading1(true);
    setloading2(true);
    Promise.allSettled([
      CustomAxios(ENDPOINTS.epDetailRecommend, "POST", { project_id: id }),
      CustomAxios(ENDPOINTS.epDetailRecommend, "POST", { project_id: id2 })
    ]).then((val) => {
      if (val[0].status === "fulfilled" && val[1].status === "fulfilled") {
        let all_years = [
          ...val[0].value.data.years,
          ...val[1].value.data.years,
        ];
        all_years = Array.from(new Set(all_years)).sort();
        setProjectA(val[0].value.data.project_name);
        setProjectB(val[1].value.data.project_name);
        let data = all_years.map((yr) => {
          return {
            name: yr,
            nopat1: val[0].value.data.incremental_nopat[yr],
            nopat2: val[1].value.data.incremental_nopat[yr],
            ep1: val[0].value.data.operating_economic_profit[yr],
            ep2: val[1].value.data.operating_economic_profit[yr],
          };
        });
        let data2 = all_years.map((yr) => {
          return {
            name: yr,
            c_ep1: val[0].value.data.cumulative_economic_profit[yr],
            c_ep2: val[1].value.data.cumulative_economic_profit[yr],
          };
        });
        setgraph1(data);
        setgraph2(data2);
      }
    }).finally(() => {
        setloading1(false);
    });
    Promise.allSettled([
      CustomAxios(ENDPOINTS.resultValidate, "POST", { project_id: id }),
      CustomAxios(ENDPOINTS.resultValidate, "POST", { project_id: id2 }),
    ]).then((val) => {
      if (val[0].status === "fulfilled") {
        setresult1(val[0].value.data);
      }
      if (val[1].status === "fulfilled") {
        setresult2(val[1].value.data);
      }
    }).finally(() => {
        setloading2(false);
    });
  }, [id, id2]);
  return (
    <div style={{ width: "100%", paddingTop: "30px" }}>
        <LoadingWrapper loading={loading1||loading2}>

      <CompareChart1
        data={graph1}
        title=""
        labels={{ ...labels, projectA, projectB }}
        />
      <CompareGraph2
        data={graph2}
        title="Cumulative EP"
        labels={{ ...labels2, projectA, projectB }}
        />
      <div className="line-chart-container"></div>
      {[result1, result2].map((data, i) => (
          <ResultProject
          key={i}
          title={i === 0 ? projectA : projectB}
          data={data}
          />
          ))}
          </LoadingWrapper>
    </div>
  );
}
export default CompareProject;
