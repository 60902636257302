import {
  IRR_NPV_RESET_STATE,
  IRR_NPV_SET_LOADING,
  IRR_NPV_SET_TABLE,
} from "../actions/irr_npv.action";

const initailState = {
  year_column: [],
  loading: false,
  data: {},
  unsaved_changes: false,
};

export const IRRNPVReducer = (state = initailState, action) => {
  switch (action.type) {
    case IRR_NPV_SET_TABLE: {
      let year_column = [];
      if (action.payload.years_list) {
        year_column = action.payload.years_list;
        delete action.payload.years_list;
      }
      return {
        ...state,
        data: action.payload,
        year_column,
      };
    }
    case IRR_NPV_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case IRR_NPV_RESET_STATE:
      return {
        ...initailState,
      };
    default:
      return state;
  }
};
