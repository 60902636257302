import React, { useEffect } from 'react';
import { Modal, Button } from 'antd';
import {
    InfoCircleOutlined
  } from '@ant-design/icons';
export default function VideoHelp ({src, title}) {
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const videoRef= React.useRef();
    const showModal = () => {
      setIsModalVisible(true);
    };
  
    const handleOk = () => {
      setIsModalVisible(false);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
    useEffect(() => {
        if(!isModalVisible) {
            videoRef.current && videoRef.current.pause();
        }
    }, [isModalVisible])
    return (
      <>
        <Button type="default" onClick={showModal} icon={<InfoCircleOutlined />}>
            Help
        </Button>
        
        <Modal style={{top: 30}} width={750} title={title} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            { isModalVisible && <video controls width={'700px'} ref={videoRef}>
                <source src={src}></source>
            </video>}
        </Modal>
      </>
    );
}