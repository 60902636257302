import { Spin } from "antd";
import React from "react";

function LoadingWrapper({ loading, children }) {
  if (!loading) {
    return children;
  } else {
    return <Spin tip="Loading...">{children}</Spin>;
  }
}
export default LoadingWrapper;
