import { message } from "antd";
import CustomAxios from "../api.service";
import { ENDPOINTS } from "../constants";

export const DEPRECIATION_RESET_STATE = "DEPRECIATION_RESET_STATE";
export const DEPRECIATION_SET_TABLE_UNSAVED_CHANGES =
  "DEPRECIATION_SET_TABLE_UNSAVED_CHANGES";
export const DEPRECIATION_SET_TABLE = "DEPRECIATION_SET_TABLE";
export const DEPRECIATION_SET_TABLE_CALCULATED =
  "DEPRECIATION_SET_TABLE_CALCULATED";
export const DEPRECIATION_SET_LOADING = "DEPRECIATION_SET_LOADING";
export const DEPRECIATION_EDIT_YEAR_INPUT = "DEPRECIATION_EDIT_YEAR_INPUT";
export const DEPRECIATION_EDIT_TOGGLE_INPUT = "DEPRECIATION_EDIT_TOGGLE_INPUT";

export const depreciationGetData = (body) => (dispatch) => {
  dispatch({ type: DEPRECIATION_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.depreciationGet, "POST", body)
    .then((val) => {
      dispatch({ type: DEPRECIATION_SET_TABLE, payload: { ...val.data } });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: DEPRECIATION_SET_LOADING, payload: false });
    });
};
export const depreciationSaveData =
  (body, checkChange) => (dispatch, getState) => {
    const depreciation = getState().depreciation;
    if (checkChange && !depreciation.unsaved_changes) {
      return;
    }
    body = {
      ...body,
      ...depreciation.data.depreciation_schedule,
      toggle_input: depreciation.data.toggle_input,
    };
    dispatch({ type: DEPRECIATION_SET_LOADING, payload: true });
    CustomAxios(ENDPOINTS.depreciationSave, "POST", { ...body })
      .then(() => {
        dispatch(depreciationGetData({ project_id: body.project_id }));
        dispatch({
          type: DEPRECIATION_SET_TABLE_UNSAVED_CHANGES,
          payload: false,
        });
        message.success("Saved Depreciation");
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch({ type: DEPRECIATION_SET_LOADING, payload: false });
      });
  };
