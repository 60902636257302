import CustomAxios from "../api.service";
import { ENDPOINTS } from "../constants";

export const IRR_NPV_RESET_STATE = "IRR_NPV_RESET_STATE";
export const IRR_NPV_SET_TABLE = "IRR_NPV_SET_TABLE";
export const IRR_NPV_SET_LOADING = "IRR_NPV_SET_LOADING";

export const IrrNpvGetData = (body) => (dispatch) => {
  dispatch({ type: IRR_NPV_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.irrNPVGet, "POST", body)
    .then((val) => {
      dispatch({ type: IRR_NPV_SET_TABLE, payload: { ...val.data } });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: IRR_NPV_SET_LOADING, payload: false });
    });
};
