import {
  BENEFITS_INPUT_EDIT_YEAR_INPUT,
  BENEFITS_INPUT_RENAME_DESC,
  BENEFITS_INPUT_RESET_STATE,
  BENEFITS_INPUT_SET_ADD_ITEM,
  BENEFITS_INPUT_SET_DELETE_ITEM,
  BENEFITS_INPUT_SET_LOADING,
  BENEFITS_INPUT_SET_TABLE,
  BENEFITS_INPUT_SET_TABLE_CALCULATED,
  BENEFITS_INPUT_SET_TABLE_UNSAVED_CHANGES,
} from "../actions/benefits.action";

const initailState = {
  year_column: [],
  loading: false,
  data: {},
  calculated: {},
  unsaved_changes: false,
};

export const BenefitsinputReducer = (state = initailState, action) => {
  switch (action.type) {
    case BENEFITS_INPUT_SET_TABLE: {
      let d = action.payload;
      if (d.years && d.project_id) {
        let year = [...d.years];
        delete d.years;
        delete d.project_id;
        return {
          ...state,
          data: d,
          year_column: year,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case BENEFITS_INPUT_SET_TABLE_CALCULATED:
      return {
        ...state,
        calculated: action.payload,
      };
    case BENEFITS_INPUT_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case BENEFITS_INPUT_SET_ADD_ITEM: {
      let data2 = { ...state.data };
      data2[action.payload.type] = [
        ...data2[action.payload.type],
        action.payload.item,
      ];
      return {
        ...state,
        data: data2,
      };
    }
    case BENEFITS_INPUT_EDIT_YEAR_INPUT: {
      let data = { ...state.data };
      if (action.payload.type=="volumn")
      {data[action.payload.type][action.payload.year]=action.payload.value;}
      else
      {
      data[action.payload.type][action.payload.key].value[action.payload.year] =action.payload.value;
      }
      return {
        ...state,
        data,
        unsaved_changes: true,
      };
    }
    case BENEFITS_INPUT_SET_DELETE_ITEM: {
      let data = { ...state.data };
      data[action.payload.type].splice(action.payload.index, 1);
      return {
        ...state,
        data,
      };
    }
    case BENEFITS_INPUT_SET_TABLE_UNSAVED_CHANGES:
      return {
        ...state,
        unsaved_changes: action.payload,
      };
    case BENEFITS_INPUT_RESET_STATE:
      return {
        ...initailState,
      };
    case BENEFITS_INPUT_RENAME_DESC: {
      let data3 = { ...state.data };
      data3[action.payload.type][action.payload.key].item_name =
        action.payload.value;
      return {
        ...state,
        data: data3,
        unsaved_changes: true,
      };
    }
    default:
      return state;
  }
};
