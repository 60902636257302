import { combineReducers } from "redux";
import { BenefitsinputReducer } from "./benefits.reducer";
import { DepriciationReducer } from "./depreciation.reducer";
import { EPProfitReducer } from "./economic_profit.reducer";
import { EPDetailReducer } from "./ep_detail_calculation.reducer";
import { FatrinputReducer } from "./fatr.reducer";
import { GrowthInputReducer } from "./growthInput.reducer";
import { InitiateProject } from "./initiateProject.reducer";
import { InvestinputReducer } from "./investInput.reducer";
import { IRRNPVReducer } from "./irr_npv.reducer";
import { KbpsinputReducer } from "./kbps.reducer";
import { netWorkingCapitalReducer } from "./netWorkingCapital.reducer";
import { PLCalcualteReducer } from "./pl_calculate.reducer";
import { ResultsReducer } from "./results.reducer";
import { SensitivitesReducer } from "./sensitivites.reducer";
import { SourceEarningReducer } from "./sourceEarning.reducer";
import { SummaryReducer } from "./summary.reducer";
import { SuperUserReducer } from "./super_user.reducer";
import { TimingExpenditureinputReducer } from "./timingExpenditure.reducer";
import { UserReducer } from "./user.reducer";
import { VolumeAssumptionReducer } from "./volumeAssumption.reducer";
import carbonFootprintReducer from "./carbon_footprint";
import SavingsReducer from "./carbon_footprint/savings.reducer";
export default combineReducers({
  initiateProject: InitiateProject,
  volumeAssumption: VolumeAssumptionReducer,
  growthInput: GrowthInputReducer,
  investInput: InvestinputReducer,
  timingExInput: TimingExpenditureinputReducer,
  benefitsInput: BenefitsinputReducer,
  kbpsInput: KbpsinputReducer,
  fatrInput: FatrinputReducer,
  user: UserReducer,
  netWorking: netWorkingCapitalReducer,
  depreciation: DepriciationReducer,
  result: ResultsReducer,
  sourceEarning: SourceEarningReducer,
  summary: SummaryReducer,
  epDetail: EPDetailReducer,
  epProfit: EPProfitReducer,
  irr_npv: IRRNPVReducer,
  super_user: SuperUserReducer,
  sensitivites: SensitivitesReducer,
  pl_calculate: PLCalcualteReducer,
  carbon_footprint: carbonFootprintReducer,
  footprint_savings: SavingsReducer,
});
