import { message } from "antd";
import CustomAxios from "../api.service";
import { ENDPOINTS } from "../constants";

export const SUPER_USER_RESET_STATE = "SUPER_USER_RESET_STATE";
export const SUPER_USER_SET_TABLE_UNSAVED_CHANGES =
  "SUPER_USER_SET_TABLE_UNSAVED_CHANGES";
export const SUPER_USER_SET_TABLE = "SUPER_USER_SET_TABLE";
export const SUPER_USER_SET_LOADING = "SUPER_USER_SET_LOADING";
export const SUPER_USER_EDIT_YEAR_INPUT = "SUPER_USER_EDIT_YEAR_INPUT";

export const superUserGetData = (body) => (dispatch) => {
  dispatch({ type: SUPER_USER_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.superUserGet, "GET", body)
    .then((val) => {
      dispatch({ type: SUPER_USER_SET_TABLE, payload: { ...val.data } });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: SUPER_USER_SET_LOADING, payload: false });
    });
};
export const superUserSaveData =
  (body, checkChange) => (dispatch, getState) => {
    const superUSer = getState().super_user;
    if (checkChange && !superUSer.unsaved_changes) {
      return;
    }
    dispatch({ type: SUPER_USER_SET_LOADING, payload: true });
    CustomAxios(ENDPOINTS.superUserSave, "POST", { ...body, ...superUSer.data })
      .then(() => {
        dispatch(superUserGetData(body));
        dispatch({
          type: SUPER_USER_SET_TABLE_UNSAVED_CHANGES,
          payload: false,
        });
        message.success("Saved superUSer Inputs");
      })
      .catch(() => {
        message.error("Error while saving super user inputs");
      })
      .finally(() => {
        dispatch({ type: SUPER_USER_SET_LOADING, payload: false });
      });
  };
