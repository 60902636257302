import React from "react";

const colors = [
  "rgb(255, 71, 26)",
  "rgb(246, 150, 30)",
  "rgb(236, 219, 35)",
  "rgb(174, 226, 40)",
  "rgb(106, 215, 45)",
  "#4caf50",
].reverse();
function Thermometer_FATR({ value = 0 }) {
  return (
    <div className="thermometer-container">
      <div className="bar-card">
        <div className="bar-title">Marginal FATR</div>
        <div className="bar-outline">
          <div className="bar">
            {colors.map((val, i) => (
              <div
                className={`sector ${
                  i === colors.length - 1 ? "last-sector" : ""
                }`}
                key={i}
                style={{ backgroundColor: val, height: '16.6%' }}
              ></div>
            ))}
            <div
              className="refer-line"
              style={{
                bottom: `${value < 0 ? 0 : value > 100 ? 100 : ((value/ 6) * 100)}%`,
              }}
            >
              <div>{value}</div>
            </div>
            <div
              className="bar-pointer bar-left"
              style={{
                backgroundColor: "rgb(255, 71, 26)",
                bottom: `${16.6 - 2.9}%`,
              }}
            >
              <div>
                1
                <div className="arrow"></div>
              </div>
            </div>
            <div
              className="bar-pointer bar-right"
              style={{
                backgroundColor: "rgb(246, 150, 30)",
                bottom: `${16.6*2 - 2.9}%`,
              }}
            >
              <div>
                2
                <div className="arrow"></div>
              </div>
            </div>
            <div
              className="bar-pointer bar-left"
              style={{
                backgroundColor: "rgb(236, 219, 35)",
                bottom: `${16.6*3 - 2.9}%`,
              }}
            >
              <div>
                3
                <div className="arrow"></div>
              </div>
            </div>
            <div
              className="bar-pointer bar-right"
              style={{
                backgroundColor: "rgb(174, 226, 40)",
                bottom: `${16.6*4 - 2.9}%`,
              }}
            >
              <div>
                4
                <div className="arrow"></div>
              </div>
            </div>
            <div
              className="bar-pointer bar-left"
              style={{
                backgroundColor: "rgb(106, 215, 45)",
                bottom: `${16.6*5 - 2.9}%`,
              }}
            >
              <div>
                5
                <div className="arrow"></div>
              </div>
            </div>
            {/* <div
              className="bar-pointer bar-right"
              style={{ backgroundColor: "#4caf50", bottom: `${100 - 2.9}%` }}
            >
              <div>
                6
                <div className="arrow"></div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Thermometer_FATR;
