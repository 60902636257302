import { message } from "antd";
import CustomAxios from "../api.service";
import { ENDPOINTS } from "../constants";

export const NET_WORKING_RESET_STATE = "NET_WORKING_RESET_STATE";
export const NET_WORKING_SET_TABLE_UNSAVED_CHANGES =
  "NET_WORKING_SET_TABLE_UNSAVED_CHANGES";
export const NET_WORKING_SET_TABLE = "NET_WORKING_SET_TABLE";
export const NET_WORKING_SET_TABLE_CALCULATED =
  "NET_WORKING_SET_TABLE_CALCULATED";
export const NET_WORKING_SET_LOADING = "NET_WORKING_SET_LOADING";
export const NET_WORKING_EDIT_YEAR_INPUT = "NET_WORKING_EDIT_YEAR_INPUT";

export const netWorkingCapitalInputGetData = (body) => (dispatch) => {
  dispatch({ type: NET_WORKING_SET_LOADING, payload: true });
  CustomAxios(ENDPOINTS.capitalInputGet, "POST", body)
    .then((val) => {
      dispatch({ type: NET_WORKING_SET_TABLE, payload: { ...val.data } });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({ type: NET_WORKING_SET_LOADING, payload: false });
    });
};
export const netWorkingCapitalInputSaveData =
  (body, checkChange) => (dispatch, getState) => {
    const netWorking = getState().netWorking;
    if (checkChange && !netWorking.unsaved_changes) {
      return;
    }
    body = {
      ...body,
      working_capital_charges: netWorking.data.working_capital_charges,
    };
    dispatch({ type: NET_WORKING_SET_LOADING, payload: true });
    CustomAxios(ENDPOINTS.capitalInputSave, "POST", { ...body })
      .then(() => {
        dispatch(netWorkingCapitalInputGetData(body));
        dispatch({
          type: NET_WORKING_SET_TABLE_UNSAVED_CHANGES,
          payload: false,
        });
        message.success("Saved Net Working Capital");
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch({ type: NET_WORKING_SET_LOADING, payload: false });
      });
  };
