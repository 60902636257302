import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "core-js/modules/es.promise";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./app";
import store from "./store";
// import { runWithAdal } from "react-adal";
// import { authContext } from "./adalConfig";
import * as Sentry from "@sentry/browser";

/* eslint-disable no-undef */
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY,
});
/* eslint-disable no-undef */

// const DO_NOT_LOGIN = true;

// runWithAdal(
//   authContext,
//   () => {
//     // eslint-disable-next-line
//     require("./indexApp.js");
//   },
//   DO_NOT_LOGIN
// );

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
