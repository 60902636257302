export const SET_FOOTPRINT_EXISTING_DATA = "set-footprint-existing-data";
export const SET_FOOTPRINT_DROPDOWN_DATA = "set-footprint-dropdown-data";
export const UPDATE_FOOTPRINT_DROPDOWN_DATA = "update-footprint-dropdown-data";
export const SET_FOOTPRINT_THERMAL_DATA = "set-footprint-thermal-data";
export const SET_FOOTPRINT_ELECTRICAL_DATA = "set-footprint-electrical-data";
export const SET_INCREMENTAL_CONSUMPTION_DATA = "set-incremental-consumption-data";
export const SET_ENERGY_INPUT_DATA = "set-energy-input-data";
export const SET_INCREMENTAL_TRANSPORT_DATA = "set-incremental-transport-data";
export const SET_CALCULATED_TRANSPORT_DATA = "set-calculated-transport-data";
export const SET_RECIPE_FOOTPRINT_DATA = "set-recipe-footprint-data";
export const SET_PACKAGING_FOOTPRINT_DATA = "set-packaging-footprint-data";
export const SET_FINAL_FOOTPRINT_DATA = "set-final-footprint-data";
export const SET_LINEITEM_VOLUME_DATA = "set-lineitem-volume-data";
export const SET_CARBON_FOOTPRINT_DATA = "set-carbon-footprint-data";
export const CLEAR_CARBON_FOOTPRINT_DATA = "clear-carbon-footprint-data";
export const CLEAR_CARBON_SAVINGS_DATA = "clear-carbon-savings-data";

export const SET_FOOTPRINT_SAVINGS_DATA = "set-footprint-savings-data";