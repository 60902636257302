/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { ENDPOINTS } from "../../constants";
import { recalculateDataAction} from "../../actions/common.action";
import {SensitivitiesCalculate} from "../../actions/sensitivites.action";
import { Modal } from "antd";
//import Modal from "antd/lib/modal/Modal";
import {
  Form,
  Input,
  Row,
  Col,
  Collapse,
  Select,
  Button,
  Card,
  DatePicker,
  message,
  Tooltip,
} from "antd";
import calender from "../../assets/images/calendar.svg";
import user from "../../assets/images/user-logo.svg";
import axios from "axios";
import { ReactComponent as ProjectDetailtLogo } from "../../assets/images/project-detail.svg";
import { ReactComponent as Dollar } from "../../assets/images/dollar.svg";
import { ReactComponent as InternalDetailLogo } from "../../assets/images/internal_detail.svg";
import { PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ReactComponent as CircleCheckLogo } from "../../assets/images/circle_check.svg";
import { ReactComponent as SaveLogo } from "../../assets/images/save.svg";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import CustomAxios from "../../api.service";
import { useHistory } from "react-router";
import {
  projectDetailSample,
  internalDetailSample,
  coreDetailSample,
} from "./sampleForms";
import { formatNum } from "../../utils/mathFn";
import formTooltip from "./formTooltip";
import {
  projectCreationGetDropdown,
  saveCapexIdeaCode
} from "../../actions/initiateProject.action";
import CustomModal from "../custom_modal";
import { Exception } from "sass";
const sense_tables = [
  "sensitivities_faa_vol",
  "sensitivities_faa_mac",
  "sensitivities_faa_earnings",
];
const { Panel } = Collapse;
const { Option } = Select;
const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};
const { TextArea } = Input;
let errorTitle = '';
let errorContent = '';
export const FormLabel = (props) => (
  <Form.Item
    {...props}
    label={
      <>
        {props.label}
        {!props.optional && <span style={{ color: "red" }}>*</span>}
        {formTooltip[props.name] && (
          <Tooltip placement="right" title={formTooltip[props.name]}>
            <InfoCircleOutlined />
          </Tooltip>
        )}
      </>
    }
  ></Form.Item>
);

function ProjectDetailEdit(props) {
  const dispatch = useDispatch();
  let userInfo = useSelector((state) => state.user);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeFormNumber, setActiveFormNumber] = useState(1);
  const [loading, setloading] = useState(false);
  const [InvestmentData, setInvestmentData] = useState({});
  const [coAuthorsList, setCoAuthorsList] = useState([]);
  const [projectDetailsForm] = Form.useForm();
  const [InternalDetailsForm] = Form.useForm();
  const [projectDetails,setProjectDetails]=useState([]);
  const [coreDetailsForm] = Form.useForm();
  const [status,setStatus]=useState("");
  const [ideaCodeIndex,setIdeaCodeIndex]=useState("");
  const [projectStatus, setProjectStatus] = useState('');
  const [projectNameDisable, setProjectNameDisable] = useState(false);
  const [pdValid, setPDValid] = useState(false);
  const [idValid, setIDValid] = useState(false);
  const [cdValid, setCDValid] = useState(false);
  const [projectT, setProjectT] = useState("");
  const projectInitiate = useSelector((state) => state.initiateProject);
  const [createdBy, setCreatedBy] = useState("");
  const [site, setSite] = useState("");
  const [segment, setsegment] = useState("");
  const [unit1, setUnit1] = useState("");
  const [unit2, setUnit2] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [projectVersion, setProjectVersion] = useState("");

  const [projectCategory, setProjectCategory] = useState("");
  const [emptyFields, setEmptyFields] = useState([]);
  const [benefitsYearList,setBenefitsYearList]=useState(projectInitiate.initial_benefit_year)
  const [benefitsPeriodList,setBenefitsPeriodList]=useState(projectInitiate.initial_benefit_period)
  const [invalidFields, setInvalidFields] = useState([]);
  const [alreadyExistFields, setAlreadyExistFields] = useState([]);
  const [validationLoading, setvalidationLoading] = useState(false);
  const [capexFieldsReadOnly, setCapexFieldsReadOnly] = useState(false);
  // Local state for the error popup
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  // Local state for the model version value
  const [modalVersionValue, setModalVersionValue] = useState('');
  const [isErrorModalVisible1, setIsErrorModalVisible1] = useState(false);
  const [isErrorModalVisible2, setIsErrorModalVisible2] = useState(false);
  const [isCodeInvalid, setIsCodeInvalid] = useState(false);
  const [capexAlphaIdeaCode, setCapexAlphaIdeaCode] = useState(0);
  const [projectLocalCurrency, setProjectLocalCurrency] = useState('');
  const [projectMpcrUsd, setProjectMpcrUsd] = useState('');
  const [capexValueDisabled, setCapexValueDisabled] = useState(false);
  const history = useHistory();
  function callApis(body)
  {
    let project_details={ project_id: body.project_id,recal_flag:true}
   dispatch(recalculateDataAction(project_details));
  dispatch(SensitivitiesCalculate(project_details,sense_tables.filter((val) => projectDetails.show_screen[val])));
}
   function recalculateData(body)
  {
    console.log("recal fun")
    console.log("projectStatus",projectStatus)
    console.log("body.model_version",body.model_version)
    if (body.model_version=="Ready for Circulation" && projectStatus=='Draft')
    callApis(body)
    else
    console.log("no recalculation")
    if(props.refresh_flag)
    setTimeout(() => {
      window.location.reload();
    }, 1500);
}
function checkbenefit()
{
  if(coreDetailsForm.getFieldValue("initial_expenditure_year")==coreDetailsForm.getFieldValue("initial_benefit_year") && coreDetailsForm.getFieldValue("initial_expenditure_period")!=undefined  && coreDetailsForm.getFieldValue("initial_expenditure_year")!=undefined && coreDetailsForm.getFieldValue("initial_expenditure_year")!=undefined)
  {
  setBenefitsPeriodList(projectInitiate.initial_benefit_period.filter(function(x) { return x >= coreDetailsForm.getFieldValue("initial_expenditure_period") }))
  if (coreDetailsForm.getFieldValue("initial_benefit_period")<coreDetailsForm.getFieldValue("initial_expenditure_period"))
  coreDetailsForm.setFieldsValue({
    initial_benefit_period: coreDetailsForm.getFieldValue("initial_expenditure_period")})
}
  else
  {
  setBenefitsPeriodList(projectInitiate.initial_expenditure_period) 
}
}
  useEffect(() => {
    if (props.editMode && props.selectedProjectID) {
      setIsModalVisible(true);
      setCapexAlphaIdeaCode(0);
      setProjectLocalCurrency('');
      setProjectMpcrUsd('');
      setCapexValueDisabled(false);
      dispatch(projectCreationGetDropdown());
      resetForm();
      CustomAxios(ENDPOINTS.getProjectDetail + props.selectedProjectID, "GET")
        .then((val) => val.data)
        .then((val) => {
          setProjectDetails(val)
          setCoAuthorsList(val.co_authors)
          
          let pd = Object.keys(projectDetailSample).filter(
            (smpl) => smpl !== "date_completed"
          );
          let id = Object.keys(internalDetailSample);
          let cd = Object.keys(coreDetailSample);
          projectDetailsForm.setFieldsValue(
            pd.reduce((acc, p) => ({ ...acc, [p]: val[p] }), {})
          );
          InternalDetailsForm.setFieldsValue(
            id.reduce((acc, p) => ({ ...acc, [p]: val[p] }), {})
          );
          projectDetailsForm.setFieldsValue({
            date_completed: val.date_completed
              ? moment(val.date_completed)
              : null,
          });
          coreDetailsForm.setFieldsValue(
            cd.reduce((acc, p) => ({ ...acc, [p]: val[p] }), {})
          );
          
          setSite(val.site);
          setsegment(val.segment);
          setUnit1(val.unit1);
          setUnit2(val.unit2);
          setCreatedBy(val.created_by);
          setProductCategory(val.product_category);
          setProjectCategory(val.project_category);
          setProjectVersion(val.model_version);
          setProjectStatus(val.model_version)
          const capexCode = projectDetailsForm.getFieldValue('cab_web_id');
          if(!(capexCode == null || capexCode == undefined || capexCode =='')){
            setCapexFieldsReadOnly(true);
          }
          else{
            setCapexFieldsReadOnly(false);
          }  
          setBenefitsYearList(projectInitiate.initial_benefit_year.filter(function(x) { return x >= coreDetailsForm.getFieldValue("initial_expenditure_year") }))
        checkbenefit()
        })
        .catch((e) => {
          message.error("Unable to load project");
        });
      CustomAxios(ENDPOINTS.irrNPVGet, "POST", {
        project_id: props.selectedProjectID,
      })
        .then((val) => val.data)
        .then((val) => {
          setInvestmentData(val);
        })
        .catch(() => {
          // message.error("Unable to load project");
        });
    } 
  }, [props.editMode]);

  useEffect(() => {
    if((capexAlphaIdeaCode > 0) && (!props.editMode)){
      let capexHierarchyData = projectInitiate.capex__hierarchy.find(o => o.project_idea_code === capexAlphaIdeaCode);
      setProjectLocalCurrency(capexHierarchyData.country);
      setProjectMpcrUsd(capexHierarchyData.mpcr_per_usd);
      setCapexValueDisabled(true);
      coreDetailsForm.setFieldsValue({
        country: capexHierarchyData.country,
        mpcr_per_usd: capexHierarchyData.mpcr_per_usd,
      });
    }
  }, [capexAlphaIdeaCode]);

  function resetForm() {
    setCapexFieldsReadOnly(false);
    projectDetailsForm.resetFields();
    InternalDetailsForm.resetFields();
    coreDetailsForm.resetFields();
    setPDValid(false);
    setCDValid(false);
    setIDValid(false);
    setProjectT("");
    setUnit1("");
    setUnit2("");
    setProductCategory("");
    setProjectCategory("");
    setInvestmentData({});
    setSite("");
  }
  function desciption_id_mapping(arry,val,col)
  {
     
      for (var i = 0; i < arry.length; i++)
      if (arry[i][col[1]]==val)
          return arry[i][col[0]]
      return null
  }
  function handleNext(tab, goToInput) {
    if (parseInt(activeFormNumber) === 1) {
      let form1 = JSON.parse(
        JSON.stringify(projectDetailsForm.getFieldsValue())
      );
      const capexCode = projectDetailsForm.getFieldValue('cab_web_id');
      if((!(capexCode == null || capexCode == undefined || capexCode ==''))){
        if (projectVersion=='Draft')
        {
        setIsErrorModalVisible1(true);
        errorTitle='Digital IRR says:'
        errorContent="The next page will be populated from CAPEX Alpha if you have selected a CAPEX Alpha project idea code"
        }  
        else
        { validateFormApi({ ...projectDetailSample, ...form1 }, tab);}
      }

      else
      { validateFormApi({ ...projectDetailSample, ...form1 }, tab);}
    } else if (parseInt(activeFormNumber) === 2) {
      let form1 = JSON.parse(
        JSON.stringify(InternalDetailsForm.getFieldsValue())
      );
      return validateFormApi(
        { ...internalDetailSample, ...form1, status: form1.model_version },
        tab
      );
    } else if (parseInt(activeFormNumber) === 3) {
      let form1 = JSON.parse(JSON.stringify(coreDetailsForm.getFieldsValue()));
      validateFormApi({ ...coreDetailSample, ...form1 }, tab, true, goToInput);
    }
  }

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setActiveFormNumber(1);
    props.seteditMode(false);
    resetForm();
  };

  const cancelHandler1=()=>{
    setProjectNameDisable(false)
    setIsErrorModalVisible1(false);
    setCapexFieldsReadOnly(false);
    if (projectStatus=="Draft"){
    projectDetailsForm.setFieldsValue({cab_web_id: null})
    projectDetailsForm.setFieldsValue({project_reference_code: '',project_name:'' });
    InternalDetailsForm.setFieldsValue({
      unit1: '',
      unit2:'',
      unit3:'',
      site:'',
      segment:'',
      project_category:'',
      project_classification:'',
      project_size:'',
      product_category:'',
      technology:'',
      brand:'',
      earnings_method:undefined,
      economic_cell:''
    });
    setProjectCategory('');
  }
  }
  const onFinish = () => {
    // pass
  };

  const onFinishFailed = () => {
    // pass
  };
  const showErrorModal = () => {
    setIsErrorModalVisible(true);
  }

  // Custom popup cancel handler
  const cancelHandler = () => {
    setIsErrorModalVisible(false);
    projectDetailsForm.setFieldsValue({
      model_version: projectVersion,
      status: projectVersion,
    });
  };
  // Custom popup ok handler
  const okHandler = () => {
    const capexCode = projectDetailsForm.getFieldValue('cab_web_id');
    if((modalVersionValue == 'Approved' || modalVersionValue == 'Rejected' || modalVersionValue == 'Ready for Circulation') && (capexCode == null || capexCode == undefined || capexCode == ''))
    {
      cancelHandler()
  }
  else
  {
    // handleStatus();
    setIsErrorModalVisible(false);
    setProjectVersion(modalVersionValue);
    projectDetailsForm.setFieldsValue({
      model_version: modalVersionValue,
      status: modalVersionValue,
    });
  }

  };


    const okHandler1 = () => {
      setIsErrorModalVisible1(false);
      if(!isCodeInvalid){
      validateFormApi({ ...projectDetailSample, ...JSON.parse(
        JSON.stringify(projectDetailsForm.getFieldsValue())
      ) }, undefined)
      }
    }

    const okHandler2 = () => {
      projectDetailsForm.setFieldsValue({
        project_name: projectInitiate.capex__hierarchy[ideaCodeIndex]["project_name"],
      });
      setProjectNameDisable(true)
      setIsErrorModalVisible2(false);
      setCapexFieldsReadOnly(true);
      projectDetailsForm.setFieldsValue({
        cab_web_id: projectInitiate.capex__hierarchy[ideaCodeIndex]["project_idea_code"],
        project_reference_code: projectInitiate.capex__hierarchy[ideaCodeIndex]["project_reference_code"]
      });  
      InternalDetailsForm.setFieldsValue({
        unit1: projectInitiate.capex__hierarchy[ideaCodeIndex]["group"],
        unit2: projectInitiate.capex__hierarchy[ideaCodeIndex]["region"],
        unit3: projectInitiate.capex__hierarchy[ideaCodeIndex]["oe"], 
        site: projectInitiate.capex__hierarchy[ideaCodeIndex]["site"],
        segment: projectInitiate.capex__hierarchy[ideaCodeIndex]["gbu"],
        project_category: projectInitiate.capex__hierarchy[ideaCodeIndex]["tier"],
        project_size: projectInitiate.capex__hierarchy[ideaCodeIndex]["project_type"],
        project_classification: projectInitiate.capex__hierarchy[ideaCodeIndex]["sub_tier"],
        product_category: projectInitiate.capex__hierarchy[ideaCodeIndex]["business_segment"],
        technology: projectInitiate.capex__hierarchy[ideaCodeIndex]["technology"],
        brand: projectInitiate.capex__hierarchy[ideaCodeIndex]["brand"],
        economic_cell: projectInitiate.capex__hierarchy[ideaCodeIndex]["economic_cell"]
      })
      setProjectCategory(projectInitiate.capex__hierarchy[ideaCodeIndex]["tier"]);
    }
  
    const cancelHandler2 = () => {
      
      setIsErrorModalVisible2(false);
      cancelHandler1()

    };
    
  function onSave(goToInput) {
    let body = {
      ...projectDetailsForm.getFieldsValue(),
      ...InternalDetailsForm.getFieldsValue(),
      ...coreDetailsForm.getFieldValue(),
      type: props.editMode ? "edit" : "save",
    };
    body.earnings_method = null;
    if (!body.product_category) {
      body.product_category = null;
    }
    if (!body.technology) {
      body.technology = null;
    }
    if (!body.ppm_id) {
      body.ppm_id = null;
    }
    if (!body.cab_web_id) {
      body.cab_web_id = null;
    }
    if (!body.project_reference_code) {
      body.project_reference_code = null;
    }
    if (!body.brand) {
      body.brand = null;
    }
    if (!body.unit3) {
      body.unit3 = null;
    }
    if (!body.economic_cell) {
      body.economic_cell = null;
    }
    if (!body.co_authors) {
      body.co_authors = [];
    }
    if (!body.date_completed) {
      body.date_completed = null;
    } else {
      body.date_completed = body.date_completed.toDate();
    }
    body.status = body.model_version;
    if(body.status == undefined || body.status == null || body.status == '')
    {
      body.status = "Draft";
      body.model_version = "Draft"
    }
    
    body.gbu_id=desciption_id_mapping(projectInitiate.gbu_mapping,body.segment,['gbu_id','gbu_description'])
    body.group_id=desciption_id_mapping(projectInitiate.group_mapping,body.unit1,['group_id','group_description'])
    body.region_id=desciption_id_mapping(projectInitiate.region_mapping,body.unit2,['region_id','region_description'])
    body.oe_id=desciption_id_mapping(projectInitiate.oe_mapping,body.unit3,['oe_id','oe_description'])
    body.brand_id=desciption_id_mapping(projectInitiate.brand_mapping,body.brand,['brand_id','brand_description'])
    body.economic_cell_id=desciption_id_mapping(projectInitiate.economic_cell_mapping,body.economic_cell,['economic_cell_id','economic_cell_description'])
    body.product_category_id=desciption_id_mapping(projectInitiate.product_category_mapping,body.product_category,['product_category_id','product_category_description'])
    body.technology_id=desciption_id_mapping(projectInitiate.technology_mapping,body.technology,['technology_id','technology_description'])
    setloading(true);
    CustomAxios(ENDPOINTS.saveProject, "POST", body)
      .then((val) => {
        setIsModalVisible(false);
        props.seteditMode(false);
        resetForm();
        setActiveFormNumber(1);
        message.success("Project saved successfully");
        dispatch(projectCreationGetDropdown());
        if (goToInput === true) {
          history.push(`/project_input/${val.data.project_id}`);
        }
        props.getAllProjects();
        recalculateData(body)
      })
      .catch((e) => {
        console.log(e)
        message.error("Error while saving project.");
      })
      .finally(() => {
        setloading(false);
      });
  }
  function validateFormApi(body, tab, startSave, goToInput) {
    setvalidationLoading(true);
    delete body.local_currency;
    CustomAxios(ENDPOINTS.validateProjectForm, "POST", {
      ...body,
      type: props.editMode ? "edit" : "save",
    })
      .then((val) => val.data)
      .then((val) => {
        setEmptyFields(val.empty_fields || []);
        setInvalidFields(val.invalid_fields || []);
        setAlreadyExistFields(val.already_exist || []);
        if (
          val.empty_fields.length === 0 &&
          val.invalid_fields.length === 0 &&
          val.already_exist.length === 0
        ) {
          switch (activeFormNumber) {
            case 1:
              setPDValid(true);
              break;
            case 2:
              setIDValid(true);
              break;
            case 3:
              setCDValid(true);
              break;
          }
          if (tab) {
            setActiveFormNumber(tab);
          } else if (activeFormNumber < 3) {
            setActiveFormNumber(parseInt(activeFormNumber) + 1);
          }
          if (startSave) {
            setTimeout(() => {
              onSave(goToInput);
            }, 0);
          }
        } else {
          switch (activeFormNumber) {
            case 1:
              setPDValid(false);
              break;
            case 2:
              setIDValid(false);
              break;
            case 3:
              setCDValid(false);
              break;
          }
        }
        setIsCodeInvalid(false);
      })
      .catch((err) => {
        try{
        if(err.response.data.indexOf('You need to change the currency assignment in Digital IRR')>-1){   
          setIsErrorModalVisible1(true);
          errorTitle='Digital IRR says:'
          errorContent=err.response.data;
          setIsCodeInvalid(true);
        }
        else{
          message.error("Failed to Validate Project");
        }
      }
      catch(e){
        message.error("Failed to Validate Project");
      }
      })
      .finally(() => {
        setvalidationLoading(false);
      });
  }
  function getValidationStatus(key) {
    if (validationLoading) {
      return "validating";
    } else if (
      emptyFields.includes(key) ||
      invalidFields.includes(key) ||
      alreadyExistFields.includes(key)
    ) {
      return "error";
    }
    return "success";
  }
  function getValidationMsg(key) {
    if (validationLoading) {
      return "";
    } else if (emptyFields.includes(key)) {
      return "field is required";
    } else if (invalidFields.includes(key)) {
      return "entered value is invalid";
    } else if (alreadyExistFields.includes(key)) {
      return "Project already exists";
    }
  }
  let form_disabled = projectVersion !== "Draft";
  let local_currency = coreDetailsForm.getFieldValue("local_currency");
  return (
    <>
    {props.isDetailsScreen == undefined &&
      <Card className="new-project-card">
        <div className="title-create">
          Create new Project
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => history.push("/simulate")}
          >
            Simulate
          </Button>
        </div>
        <div className="project-input-row">
          <Input
            placeholder={"Enter project title"}
            value={projectT}
            onChange={(e) => setProjectT(e.target.value)}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsModalVisible(true);
              projectDetailsForm.setFieldsValue({
                project_name: projectT,
              });
              setProjectVersion("Draft");
            }}
          >
            Add Project
          </Button>
        </div>
        <div className="title-create" style={{ paddingTop: "10px" }}>
          {!props.compare ? (
            <Button
              style={{ backgroundColor: "green" }}
              type="primary"
              onClick={() => props.setCompare(true)}
            >
              Compare Projects
            </Button>
          ) : (
            <>
              <Button
                type="primary"
                style={{ backgroundColor: "green", marginLeft: "10px" }}
                onClick={() => {
                  props.setCompare(false);
                  props.compareSelected();
                }}
              >
                Compare Selected
              </Button>
              <Button
                danger
                type="primary"
                style={{ backgroundColor: "red" }}
                onClick={() => props.setCompare(false)}
              >
                Cancel
              </Button>
            </>
          )}
        </div>
      </Card>}
      <Modal
        width={1050}
        style={{ top: 0 }}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        closable={!loading}
        className="project-form-create-modal"
      >
        <div className="project-detail-edit-top-info">
          <span>
            <img alt="icon" src={user} />
            {props.name}
          </span>
          <span>
            <img alt="icon" src={calender} />
            {new Date().toLocaleDateString()}
          </span>
        </div>
        <Collapse
          onChange={() => {
            // on collpase of card
          }}
          expandIconPosition={"right"}
          expandIcon={(panel) => {
            if (panel.panelKey === "1" && pdValid) {
              return <CircleCheckLogo className="check-circle" />;
            } else if (panel.panelKey === "2" && idValid) {
              return <CircleCheckLogo className="check-circle" />;
            } else if (panel.panelKey === "3" && cdValid) {
              return <CircleCheckLogo className="check-circle" />;
            } else if (panel.isActive) {
              return "";
            } else {
              return "";
            }
          }}
          accordion
          activeKey={activeFormNumber}
          bordered={false}
          className="site-collapse-custom-collapse"
        >
          <Panel
            className={`site-collapse-custom-panel`}
            header={
              <>
                <ProjectDetailtLogo />
                Project Details
              </>
            }
            key="1"
          >
            <Form
              {...layout}
              name="basic"
              //   initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              colon={false}
              form={projectDetailsForm}
            >
              <Row>
                <Col span={10}>
                  <FormLabel
                    validateStatus={getValidationStatus("project_id")}
                    help={
                      props.editMode
                        ? null
                        : "id will be auto generated at project creation"
                    }
                    label="Project ID "
                    name="project_id"
                    optional={true}
                    className="project-id-label"
                  >
                    <Input placeholder="" disabled={true} />
                  </FormLabel>
                  <FormLabel
                    validateStatus={getValidationStatus("project_name")}
                    help={getValidationMsg("project_name")}
                    label="Project Name "
                    name="project_name"
                  >
                    <Input disabled={form_disabled||projectNameDisable} placeholder="Enter" />
                  </FormLabel>
                  <FormLabel
                    validateStatus={getValidationStatus("short_description")}
                    help={getValidationMsg("short_description")}
                    label="Short Description"
                    name="short_description"
                  >
                    <TextArea
                      rows={2}
                      maxLength={99}
                      disabled={form_disabled}
                    />
                  </FormLabel>
                  
                  <FormLabel
                    validateStatus={getValidationStatus("ppm_id")}
                    help={getValidationMsg("ppm_id")}
                    optional={true}
                    label="Inno Tool / PPM ID"
                    name="ppm_id"
                  >
                    <Select
                      disabled={form_disabled}
                      showSearch
                      placeholder="Select"
                      allowClear
                      onChange={(e) => {
                        projectDetailsForm.setFieldsValue({ ppm_id: e });
                      }}
                    >
                      {Object.entries(projectInitiate.ppm_mapping).map(
                        (val, key) => (
                          <Option
                            title={val[1]}
                            key={`pr-${key}`}
                            value={val[0]}
                          >
                            {val[0]} ({val[1]})
                          </Option>
                        )
                      )}
                    </Select>
                  </FormLabel>

                  <FormLabel
                    validateStatus={getValidationStatus("date_completed")}
                    optional={true}
                    help={getValidationMsg("date_completed")}
                    label="Last Updated"
                    name="date_completed"
                  >
                    <DatePicker
                      style={{ background: "#F2F2F2" }}
                      format={"MM/DD/YYYY"}
                      placeholder="MM/DD/YYYY"
                      disabled={true}
                      defaultValue={moment()}
                    
                      disabledDate={(current) => {
                        let customDate = moment().format("YYYY-MM-DD")
                        return current && current > moment(customDate, "YYYY-MM-DD").add(1, 'days');;
                      }} 
                    />
                  </FormLabel>
                </Col>
                <Col span={14}>
                <FormLabel
                    validateStatus={getValidationStatus("model_version")}
                    help={getValidationMsg("model_version")}
                    label="Model Version"
                    name="model_version"
                  >
                    <Select
                    defaultValue="Draft"
                      disabled={
                        (projectStatus == "Approved" || 
                        projectStatus=="Ready for Circulation" ||
                        projectStatus =="Rejected" ||
                        projectStatus =="Circulating")
                         && !props.is_superuser
                      }
                      showSearch
                      placeholder="Select"
                      onChange={(e) => {
                        const capexCode = projectDetailsForm.getFieldValue('cab_web_id');
                        errorTitle = 'Digital IRR says:';
                        setModalVersionValue(e);
                        if((e == 'Approved' || e == 'Rejected' || e == 'Ready for Circulation') && (capexCode == null || capexCode == undefined || capexCode == ''))
                        {
                          errorContent = 'Please select CAPEX Alpha Project Idea to continue';  
                          showErrorModal()    
                        }
                        else if (e == 'Approved' || e == 'Rejected'){
                          errorContent = 'You are about to change the status of this project to approved or Rejected, this change will be definitive. If after that change you still want to re-use this project, you can duplicate but it will have a new Project ID. Do you confirm your choice ?';  
                          showErrorModal()
                            setStatus(e)
                        }
                        else{
                          
                          var year = moment().year();
                          if (e == 'Ready for Circulation' && ! [year-1,year,year+1,year+2].includes(coreDetailsForm.getFieldValue("initial_expenditure_year")))
                        {      
                            message.error({key: 'msgKey',content: <div> CAPEX Alpha does not accept projects for circulation that do not <br/>start within the range (Current Year -1, Current Year + 2)
                            </div>
                            ,
                            duration:3,onClick:(() => message.destroy('msgKey'))
                            }
                            );
                          cancelHandler();
                        }
                        else
                      {
                          projectDetailsForm.setFieldsValue({
                            model_version: e,
                            status: e,
                          });
                          setProjectVersion(e);
                        }
                        }
                        
                      }}
                    >
                      {projectInitiate.model_version
                        ? projectInitiate.model_version.map((val, key) => (
                            <Option key={`pr-${key}`} value={val}>
                              {val}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </FormLabel>
                  <FormLabel
                    validateStatus={getValidationStatus("cab_web_id")}
                    //help={getValidationMsg("cab_web_id")}
                    optional={true}
                    label="CAPEX Alpha Project Idea Code"
                    name="cab_web_id"
                    help={
                  "Please select from the list the corresponding CAPEX ALPHA Idea code. If you cannot find it, this means that another project has already selected it and there can be only one. To find out this other project, please go back to the repository and type your idea code in the search bar above."
                 
                    }
                  >

                  <Select
                      disabled={form_disabled}
                      showSearch
                      placeholder="Select"
                      allowClear
                      onChange={(e) => {
                        setIsCodeInvalid(false);
                        //dispatch(saveCapexIdeaCode(e));
                        setCapexAlphaIdeaCode(e);
                        var index = 0;
                        for (var i=0 ; i < projectInitiate.capex__hierarchy.length ; i++)
                        {
                            if (projectInitiate.capex__hierarchy[i]["project_idea_code"] == e) {
                              index = i;
                                break;
                            }
                        }
                        if(e==undefined || e == '')
                          {
                          setProjectNameDisable(false)
                          setCapexFieldsReadOnly(false);
                          projectDetailsForm.setFieldsValue({project_reference_code: '' ,project_name:''});
                          InternalDetailsForm.setFieldsValue({
                            unit1: '',
                            unit2:'',
                            unit3:'',
                            site:'',
                            segment:'',
                            project_category:'',
                            project_classification:'',
                            project_size:'',
                            product_category:'',
                            technology:'',
                            brand:'',
                            earnings_method:undefined,
                            economic_cell:''
                          });
                          setProjectCategory('');
                        }
                        else{   
                          setIdeaCodeIndex(index)
                          errorTitle='Digital IRR says:'
                          errorContent=<p>
                          The name of the project in CAPEX ALPHA Idea Code <b> {e} </b>  is <b>{projectInitiate.capex__hierarchy[index]["project_name"]}</b>.Is this project to which this Digital IRR calculation is to be linked? If you confirm, project details on the next screen as well as the Project Name in this Digital IRR Project will be updated to match those in CAPEX ALPHA
                          </p>
                          setIsErrorModalVisible2(true)
                        }
                      }}
                    >
                      {projectInitiate.capex__hierarchy.map(
                        (val,index) => (
                          <Option
                            title={val["project_idea_code"]}
                            value={val["project_idea_code"]}
                          >
                            {val["project_idea_code"]}
                          </Option>
                        )
                      )}

                    </Select>
                     
                  </FormLabel>
                  <FormLabel
                    validateStatus={getValidationStatus("project_reference_code")}
                    help={getValidationMsg("project_reference_code")}
                    optional={true}
                    label="CAPEX Alpha Project Reference Code"
                    name="project_reference_code"
                  >
                    <Input
                      disabled={true}
                      style={{ color: "#0d0c0c" }}
                     // type="number"
                    />
                  </FormLabel>
                </Col>
              </Row>
            </Form>
          </Panel>
          <Panel
            className={`site-collapse-custom-panel ${
              validationLoading || loading ? "no-events" : ""
            }`}
            header={
              <>
                <InternalDetailLogo />
                Common Details with CAPEX Alpha
              </>
            }
            key="2"
          >
            <Form
              {...layout}
              name="basic"
              //   initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              colon={false}
              form={InternalDetailsForm}
            >
              <Row>
                <Col span={8}>
                  <FormLabel
                    validateStatus={getValidationStatus("site")}
                    help={getValidationMsg("site")}
                    label="Site"
                    name="site"
                  >
                    <Select
                      disabled={form_disabled || capexFieldsReadOnly}
                      showSearch
                      placeholder="Select"
                      onChange={(e) => {
                        InternalDetailsForm.setFieldsValue({ site: e });
                        setSite(e);
                      }}
                    >
                      {Object.keys(projectInitiate.site_mapping).map(
                        (val, key) => (
                          <Option key={`pr-${key}`} value={val}>
                            <div>
                              {val}{" "}
                              {projectInitiate.site_mapping[site] && (
                                <Tooltip
                                  placement="right"
                                  title={
                                    <div>
                                      <b>Site Name :</b>{" "}
                                      {
                                        projectInitiate.site_mapping[site][0]
                                          .site_name
                                      }{" "}
                                      <br />
                                      <b>City :</b>{" "}
                                      {
                                        projectInitiate.site_mapping[site][0]
                                          .city
                                      }{" "}
                                      <br />
                                      <b>Segment :</b>{" "}
                                      {
                                        projectInitiate.site_mapping[site][0]
                                          .segment
                                      }{" "}
                                      <br />
                                      <b>Country :</b>{" "}
                                      {
                                        projectInitiate.site_mapping[site][0]
                                          .country
                                      }
                                    </div>
                                  }
                                >
                                  <InfoCircleOutlined />
                                </Tooltip>
                              )}
                            </div>
                          </Option>
                        )
                      )}
                    </Select>
                  </FormLabel>
                  <FormLabel
                    validateStatus={getValidationStatus("segment")}
                    help={getValidationMsg("segment")}
                    label="GBU"
                    name="segment"
                  >
                    <Select
                      disabled={form_disabled || capexFieldsReadOnly}
                      showSearch
                      placeholder="Select"
                      onChange={(e) => {

                        if(projectDetailsForm.getFieldValue("cab_web_id") == undefined)
                        {
                            let unit_one = undefined;
                            let unit1_options = Object.keys(
                              projectInitiate.unit_hierarchy[e]
                            );
                            if (unit1_options.length === 1) {
                              unit_one = unit1_options[0];
                              setUnit1(unit_one);
                            } else {
                              setUnit1("");
                            }
                            setsegment(e);
                            InternalDetailsForm.setFieldsValue({
                              segment: e,
                              unit1: unit_one,
                              unit2: undefined,
                              unit3: undefined,
                            });
                            setUnit2("");
                        }
                      }}
                    >
                      {Object.keys(projectInitiate.unit_hierarchy).map(
                        (val, key) => (
                          <Option key={`pr-${key}`} value={val}>
                            {val}
                          </Option>
                        )
                      )}
                    </Select>
                  </FormLabel>
                  <FormLabel
                    validateStatus={getValidationStatus("unit1")}
                    help={getValidationMsg("unit1")}
                    label="Group"
                    name="unit1"
                  >
                    <Select
                      disabled={form_disabled || capexFieldsReadOnly}
                      showSearch
                      placeholder="Select"
                      onChange={(e) => {
                        let unit_two = undefined;
                        let unit2_options = Object.keys(
                          projectInitiate.unit_hierarchy[segment][e]
                        );
                        if (unit2_options.length === 1) {
                          unit_two = unit2_options[0];
                          setUnit2(unit_two);
                        } else {
                          setUnit2("");
                        }
                        InternalDetailsForm.setFieldsValue({
                          unit1: e,
                          unit2: unit_two,
                          unit3: undefined,
                        });
                        setUnit1(e);
                      }}
                    >
                      {projectInitiate.unit_hierarchy[segment]
                        ? Object.keys(
                            projectInitiate.unit_hierarchy[segment]
                          ).map((val, key) => (
                            <Option key={`pr-${key}`} value={val}>
                              {val}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </FormLabel>
                  <FormLabel
                    validateStatus={getValidationStatus("unit2")}
                    help={getValidationMsg("unit2")}
                    label="Region"
                    name="unit2"
                  >
                    <Select
                      disabled={form_disabled || capexFieldsReadOnly}
                      showSearch
                      placeholder="Select"
                      onChange={(e) => {
                        InternalDetailsForm.setFieldsValue({
                          unit2: e,
                          unit3: undefined,
                        });
                        setUnit2(e);
                      }}
                    >
                      {projectInitiate.unit_hierarchy[segment] &&
                      projectInitiate.unit_hierarchy[segment][unit1]
                        ? Object.keys(
                            projectInitiate.unit_hierarchy[segment][unit1]
                          ).map((val, key) => (
                            <Option key={`pr-${key}`} value={val}>
                              {val}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </FormLabel>
                  <FormLabel
                    validateStatus={getValidationStatus("unit3")}
                    help={getValidationMsg("unit3")}
                    label="OE"
                    name="unit3"
                  >
                    <Select
                      disabled={form_disabled || capexFieldsReadOnly}
                      showSearch
                      allowClear
                      placeholder="Select"
                      onChange={(e) => {
                        InternalDetailsForm.setFieldsValue({ unit3: e });
                      }}
                    >
                      {projectInitiate.unit_hierarchy[segment] &&
                      projectInitiate.unit_hierarchy[segment][unit1] &&
                      projectInitiate.unit_hierarchy[segment][unit1][unit2]
                        ? projectInitiate.unit_hierarchy[segment][unit1][
                            unit2
                          ].map((val, key) => (
                            <Option key={`pr-${key}`} value={val}>
                              {val}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </FormLabel>
                  </Col>
                  <Col span={8}>
                  <FormLabel
                    validateStatus={getValidationStatus("project_size")}
                    help={getValidationMsg("project_size")}
                    label="Project Type"
                    name="project_size"
                  >
                    <Select
                      disabled={form_disabled || capexFieldsReadOnly}
                      showSearch
                      placeholder="Select"
                      onChange={(e) =>
                        InternalDetailsForm.setFieldsValue({ project_size: e })
                      }
                    >
                      {projectInitiate.project_size.map((val, key) => (
                        <Option key={`pr-${key}`} value={val}>
                          {val}
                        </Option>
                      ))}
                    </Select>
                  </FormLabel>

                  <FormLabel
                    validateStatus={getValidationStatus("project_category")}
                    help={getValidationMsg("project_category")}
                    label="Tier"
                    name="project_category"
                  >
                    <Select
                      disabled={form_disabled || capexFieldsReadOnly}
                      showSearch
                      placeholder="Select"
                      onChange={(e) => {
                        let earnings_method = undefined;
                        if (
                          projectInitiate.project_heirarchy[e].earnings_method
                            .length === 1
                        ) {
                          // earnings_method =
                          //   projectInitiate.project_heirarchy[e]
                          //     .earnings_method[0];
                          earnings_method = null;
                        }
                        InternalDetailsForm.setFieldsValue({
                          project_category: e,
                          project_classification: undefined,
                          earnings_method: null,
                        });
                        setProjectCategory(e);
                      }}
                    >
                      {projectInitiate.project_heirarchy
                        ? Object.keys(projectInitiate.project_heirarchy).map(
                            (val, key) => (
                              <Option key={`pr-${key}`} value={val}>
                                {val}
                              </Option>
                            )
                          )
                        : ""}
                    </Select>
                  </FormLabel>
                  <FormLabel
                    validateStatus={getValidationStatus(
                      "project_classification"
                    )}
                    help={getValidationMsg("project_classification")}
                    label="Sub-Tier"
                    name="project_classification"
                  >
                    <Select
                      disabled={form_disabled || capexFieldsReadOnly}
                      showSearch
                      placeholder="Select"
                      onChange={(e) => {
                        InternalDetailsForm.setFieldsValue({
                          project_classification: e,
                        });
                      }}
                    >
                      {projectInitiate.project_heirarchy &&
                      projectInitiate.project_heirarchy[projectCategory]
                        ? projectInitiate.project_heirarchy[
                            projectCategory
                          ].project_classification.map((val, key) => (
                            <Option key={`pr-${key}`} value={val}>
                              {val}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </FormLabel>
                  <FormLabel
                    validateStatus={getValidationStatus("economic_cell")}
                    help={getValidationMsg("economic_cell")}
                    label="Economic Cell"
                    name="economic_cell"
                  >
                    <Select
                      disabled={form_disabled || capexFieldsReadOnly}
                      showSearch
                      placeholder="Select"
                      onChange={(e) =>
                        InternalDetailsForm.setFieldsValue({ economic_cell: e })
                      }
                    >
                      {projectInitiate.economic_cell.map((val, key) => (
                        <Option key={`pr-${key}`} value={val}>
                          {val}
                        </Option>
                      ))}
                    </Select>
                  </FormLabel>

                  <FormLabel
                    validateStatus={getValidationStatus("earnings_method")}
                    help={getValidationMsg("earnings_method")}
                    label="Earning Method"
                    name="earnings_method"
                    hidden={true}
                  >
                    <Select
                      disabled={form_disabled}
                      showSearch
                      placeholder="Select"
                      onChange={(e) =>
                        InternalDetailsForm.setFieldsValue({
                            earnings_method: null,
                        })
                      }
                    >
                      {projectInitiate.project_heirarchy &&
                      projectInitiate.project_heirarchy[projectCategory]
                        ? projectInitiate.project_heirarchy[
                            projectCategory
                          ].earnings_method.map((val, key) => (
                            <Option key={`pr-${key}`} value={val}>
                              {val}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </FormLabel>
                </Col>
                <Col span={8}>
                  <FormLabel
                    optional={true}
                    validateStatus={getValidationStatus("product_category")}
                    help={getValidationMsg("product_category")}
                    label="Product Category"
                    name="product_category"
                  >
                    <Select
                      allowClear
                      disabled={form_disabled || capexFieldsReadOnly}
                      showSearch
                      placeholder="Select"
                      onChange={(e) => {
                        /*projectDetailsForm.setFieldsValue({
                          product_category: e,
                          technology: undefined,
                        });*/
                        InternalDetailsForm.setFieldsValue({
                          product_category: e,
                         // technology: undefined,
                        });
                        setProductCategory(e);
                      }}
                    >
                      {projectInitiate.product_heirarchy
                        ? projectInitiate.product_heirarchy.map(
                            (val, key) => (
                              <Option key={`pr-${key}`} value={val}>
                                {val}
                              </Option>
                            )
                          )
                        : ""}
                    </Select>
                  </FormLabel>
                  <FormLabel
                    optional={true}
                    validateStatus={getValidationStatus("technology")}
                    help={getValidationMsg("technology")}
                    label="Technology"
                    name="technology"
                  >
                    <Select
                      allowClear
                      disabled={form_disabled || capexFieldsReadOnly}
                      showSearch
                      placeholder="Select"
                      onChange={(e) => {
                        //projectDetailsForm.setFieldsValue({ technology: e });
                        InternalDetailsForm.setFieldsValue({ technology: e });
                      }}
                    >
                      {projectInitiate.technology 
                        ? projectInitiate.technology.map((val, key) => (
                            <Option key={`pr-${key}`} value={val}>
                              {val}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </FormLabel>
                  <FormLabel
                    optional={true}
                    validateStatus={getValidationStatus("brand")}
                    help={getValidationMsg("brand")}
                    label="Brand"
                    name="brand"
                  >
                    <Select
                      disabled={form_disabled || capexFieldsReadOnly}
                      showSearch
                      placeholder="Select"
                      allowClear
                      onChange={(e) => {
                        //projectDetailsForm.setFieldsValue({ brand: e });
                        InternalDetailsForm.setFieldsValue({ brand: e });
                      }}
                    >
                      {projectInitiate.brand_hierarchy ?
                       projectInitiate.brand_hierarchy.map(
                            (val, key) => (
                              <Option key={`pr-${key}`} value={val}>
                                {val}
                              </Option>
                            )
                          )
                        : ""}
                    </Select>
                  </FormLabel>
                  
                                 </Col>
              </Row>
            </Form>
          </Panel>
          <Panel
            className={`site-collapse-custom-panel ${
              validationLoading || loading ? "no-events" : ""
            }`}
            header={
              <>
                <Dollar />
                Core Details
              </>
            }
            key="3"
          >
            <Form
              {...layout}
              name="basic"
              //   initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              // requiredMark={false}
              colon={false}
              form={coreDetailsForm}
            >
              <Row gutter={15}>
                <Col span={12}>
                  <FormLabel
                    validateStatus={getValidationStatus(
                      "initial_expenditure_year"
                    )}
                    help={getValidationMsg("initial_expenditure_year")}
                    label="Initial Expenditure Year"
                    name="initial_expenditure_year"
                  >
                    <Select
                      disabled={form_disabled}
                      showSearch
                      placeholder="Select"
                      onChange={(e) =>
                        (
                        coreDetailsForm.setFieldsValue({
                          initial_expenditure_year: e,
                        }),
                        setBenefitsYearList(projectInitiate.initial_benefit_year.filter(function(x) { return x >= coreDetailsForm.getFieldValue("initial_expenditure_year")})),
                        coreDetailsForm.getFieldValue("initial_benefit_year")<coreDetailsForm.getFieldValue("initial_expenditure_year")?
                        coreDetailsForm.setFieldsValue({
                          initial_benefit_year: e,})
                        :"",
                        checkbenefit()
                        )
                      }
                    >
                      {projectInitiate.initial_expenditure_year.map(
                        (val, key) => (
                          <Option key={`pr-${key}`} value={val}>
                            {val}
                          </Option>
                        )
                      )}
                    </Select>
                  </FormLabel>
                  <FormLabel
                    validateStatus={getValidationStatus(
                      "initial_expenditure_period"
                    )}
                    help={getValidationMsg("initial_expenditure_period")}
                    label="Initial Expenditure Period"
                    name="initial_expenditure_period"
                  >
                    <Select
                      disabled={form_disabled}
                      showSearch
                      placeholder="Select"
                      onChange={(e) =>
                        (
                          coreDetailsForm.setFieldsValue({
                            initial_expenditure_period: e,
                          }),
                          checkbenefit()
                          )
                      }
                    >
                      {projectInitiate.initial_expenditure_period.map(
                        (val, key) => (
                          <Option key={`pr-${key}`} value={val}>
                            {val}
                          </Option>
                        )
                      )}
                    </Select>
                  </FormLabel>
                  <FormLabel
                    validateStatus={getValidationStatus("initial_benefit_year")}
                    help={getValidationMsg("initial_benefit_year")}
                    label="Initial Benefit Year"
                    name="initial_benefit_year"
                  >
                    <Select
                      disabled={form_disabled}
                      showSearch
                      placeholder="Select"
                      
                      onChange={(e) =>
                        (
                        coreDetailsForm.setFieldsValue({
                          initial_benefit_year: e,
                        }),
                        checkbenefit()
                        )
                      }
                    >
                      { benefitsYearList.length>0?
                        benefitsYearList.map((val, key) => (
                        <Option key={`pr-${key}`} value={val}>
                          {val}
                        </Option>
                      )):
                      projectInitiate.initial_benefit_year.map((val, key) => (
                        <Option key={`pr-${key}`} value={val}>
                          {val}
                        </Option>
                      ))
                    }
                    </Select>
                  </FormLabel>
                  <FormLabel
                    validateStatus={getValidationStatus(
                      "initial_benefit_period"
                    )}
                    className="benefits-input"
                    help={`${
                      getValidationMsg("initial_benefit_period")
                        ? `${getValidationMsg("initial_benefit_period")}\n`
                        : ""
                    }This will be the period to start depreciation `}
                    label="Initial Benefit Period"
                    name="initial_benefit_period"
                  >
                    <Select
                      disabled={form_disabled}
                      showSearch
                      placeholder="Select"
                      onChange={(e) =>
                        coreDetailsForm.setFieldsValue({
                          initial_benefit_period: e,
                        })
                      }
                    >
                      {benefitsPeriodList.length>0?benefitsPeriodList.map(
                        (val, key) => (
                          <Option key={`pr-${key}`} value={val}>
                            {val}
                          </Option>
                        )
                      ):projectInitiate.initial_benefit_period.map(
                        (val, key) => (
                          <Option key={`pr-${key}`} value={val}>
                            {val}
                          </Option>
                        )
                      )
                      }
                    </Select>
                  </FormLabel>
                </Col>
                <Col span={12}>
                  <FormLabel
                    validateStatus={getValidationStatus("local_tax_rate")}
                    help={getValidationMsg("local_tax_rate")}
                    label="Local Tax Rate"
                    name="local_tax_rate"
                  >
                    <Input
                      disabled={form_disabled}
                      type="number"
                      placeholder="Enter"
                      suffix="%"
                    />
                  </FormLabel>
                  <FormLabel
                    validateStatus={getValidationStatus("country")}
                    help={getValidationMsg("country")}
                    label="Local Currency"
                    name="country"
                  >
                    <Select
                      disabled={capexValueDisabled ? capexValueDisabled : form_disabled}
                      showSearch
                      placeholder="Select"
                      defaultValue={projectLocalCurrency}
                      onChange={(e) => {
                        coreDetailsForm.setFieldsValue({
                          country: e,
                          mpcr_per_usd: projectInitiate.currency_mapping[e],
                        });
                      }}
                    >
                      {Object.keys(projectInitiate.currency_mapping).map(
                        (val, key) => (
                          <Option key={`pr-${key}`} value={val}>
                            {val}
                          </Option>
                        )
                      )}
                    </Select>
                  </FormLabel>
                  <FormLabel
                    validateStatus={getValidationStatus("mpcr_per_usd")}
                    help={getValidationMsg("mpcr_per_usd")}
                    optional={true}
                    label="MPCR (Per USD)"
                    name="mpcr_per_usd"
                  >
                    <Input
                     disabled={true}
                      // disabled={capexValueDisabled ? capexValueDisabled : form_disabled}
                      defaultValue={projectMpcrUsd}
                      // style={{ color: "#0d0c0c" }}
                      type="number"
                    />
                  </FormLabel>
                  <FormLabel
                    validateStatus={getValidationStatus("confidentiality")}
                    help={getValidationMsg("confidentiality")}
                    label="Confidentiality"
                    name="confidentiality"
                  >
                    <Select
                      showSearch
                      placeholder="Select"
                      onChange={(e) => {
                        coreDetailsForm.setFieldsValue({
                          confidentiality: e,
                        });
                      }}
                    >
                      {["Private", "Public"].map((val, key) => (
                        <Option key={`pr-${key}`} value={val}>
                          {val}
                        </Option>
                      ))}
                    </Select>
                  </FormLabel>
                  {
                    <FormLabel
                      validateStatus={getValidationStatus("co_authors")}
                      help={getValidationMsg("co_authors")}
                      label="Co Authors"
                      name="co_authors"
                      optional={true}
                    >
                      <Select
                        showSearch
                        placeholder="Select"
                        mode="multiple"
                        onChange={(e) => {
                          coreDetailsForm.setFieldsValue({
                            co_authors: e,
                          });
                        }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {projectInitiate.userlist.map((val) => (
                          <Option key={val.email} value={val.id}>
                            {val.email}
                          </Option>
                        ))}
                      </Select>
                    </FormLabel>
                  }
                </Col>
              </Row>
            </Form>
            {props.editMode && (
              <div className="total-row">
                <span>
                  Capital Expenditure :
                  <b>
                    {formatNum(InvestmentData.capital_expenditure)}
                    <span className="dim-info"> K {local_currency}</span>
                  </b>
                </span>
                <span>
                  Project Expense :
                  <b>
                    {formatNum(InvestmentData.project_expense)}
                    <span className="dim-info"> K {local_currency}</span>
                  </b>
                </span>
                <span>
                  Project Investment :
                  <b>
                    {formatNum(InvestmentData.total_project_investment)}
                    <span className="dim-info"> K {local_currency}</span>
                  </b>
                </span>
                <span>
                  Project IRR :
                  <b> {formatNum(InvestmentData.base_case_irr)}%</b>
                </span>
              </div>
            )}
          </Panel>
        </Collapse>
        <div className="project-add-button">
          {activeFormNumber === 3 ? (
            <>
              {!props.editMode && (
                <Button
                  loading={loading || validationLoading}
                  type="primary"
                  onClick={() => handleNext(null, true)}
                >
                  Continue Input
                </Button>
              )}
              <Button
                loading={loading || validationLoading}
                type="text"
                className="save-button"
                icon={<SaveLogo />}
                onClick={() => handleNext()}
              >
                Save Draft
              </Button>
            </>
          ) : (
            <div>
              <Button
                loading={validationLoading}
                className="next-button"
                onClick={() => handleNext()}
              >
                Next
              </Button>
            </div>
          )}
          {!loading && activeFormNumber !== 1 && (
            <Button
              loading={loading || validationLoading}
              className="next-button"
              onClick={() => setActiveFormNumber(activeFormNumber - 1)}
              style={{ marginRight: "20px" }}
            >
              Previous
            </Button>
          )}
          <div style={{ flexGrow: 1 }}>
            {" "}
            All fields marked with <span style={{ color: "red" }}>*</span> are
            mandatory.
          </div>
        </div>
      </Modal>
      {isErrorModalVisible && <CustomModal title={errorTitle} content={errorContent} isModalVisible={isErrorModalVisible}
      updateOk={() => okHandler()} updateCancel={() => cancelHandler()}  />}
      {isErrorModalVisible1 && <CustomModal title={errorTitle} content={errorContent} isModalVisible={isErrorModalVisible1}
      updateOk={() => okHandler1()} updateCancel={() => 
        cancelHandler1()
      }  />}
    
    {isErrorModalVisible2 && <CustomModal title={errorTitle} content={errorContent} isModalVisible={isErrorModalVisible2}
      updateOk={() => okHandler2()} updateCancel={() => 
        cancelHandler2()
      }  />}
      
    </>
  );
}
ProjectDetailEdit.defaultProps = {
  name: "",
};
export default ProjectDetailEdit;