import {
  SUMMARY_RESET_STATE,
  SUMMARY_SET_LOADING,
  SUMMARY_SET_LOADING_OVERVIEW,
  SUMMARY_SET_PROJECT_OVERVIEW,
  SUMMARY_SET_PROJECT_OVERVIEW_CHANGE_INPUT,
  SUMMARY_SET_TABLE,
  SUMMARY_SET_UNSAVED_CHANGES_OVERVIEW,
} from "../actions/summary.action";

const initailState = {
  year_column: [],
  loading: false,
  loading_overview: false,
  project_overview: {},
  data: {},
  unsaved_changes: false,
};

export const SummaryReducer = (state = initailState, action) => {
  switch (action.type) {
    case SUMMARY_SET_TABLE: {
      let d = action.payload;
      if (d.years) {
        let year = [...d.years];
        delete d.years;
        return {
          ...state,
          data: d,
          year_column: year,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case SUMMARY_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SUMMARY_SET_LOADING_OVERVIEW:
      return {
        ...state,
        loading_overview: action.payload,
      };
    case SUMMARY_SET_PROJECT_OVERVIEW:
      return {
        ...state,
        project_overview: action.payload,
      };
    case SUMMARY_SET_PROJECT_OVERVIEW_CHANGE_INPUT:
      return {
        ...state,
        project_overview: {
          ...state.project_overview,
          [action.payload.key]: action.payload.value,
        },
        unsaved_changes: true,
      };
    case SUMMARY_SET_UNSAVED_CHANGES_OVERVIEW:
      return {
        ...state,
        unsaved_changes: action.payload,
      };
    case SUMMARY_RESET_STATE:
      return {
        ...initailState,
      };
    default:
      return state;
  }
};
