import {
  SENSITIVITIES_CHANGE_INPUT,
  SENSITIVITIES_RESET_STATE,
  SENSITIVITIES_SET_INPUT_DATA,
  SENSITIVITIES_SET_LOADING,
  SENSITIVITIES_SET_LOADING_calculate,
  SENSITIVITIES_SET_TABLE,
  SENSITIVITIES_UNSAVED_CHANGES,
} from "../actions/sensitivites.action";

const inititalState = {
  faa_vol_per: [],
  faa_mac_per: [],
  mac_per: [],
  vol_per: [],
  earnings_per: [],
  faa_earnings_per: [],

  faa_mac_list: [],
  faa_vol_list: [],
  mac_faa_matrix: [],
  mac_list: [],
  vol_faa_matrix: [],
  vol_list: [],

  faa_mac_list_ep: [],
  faa_vol_list_ep: [],
  mac_faa_matrix_ep: [],
  mac_list_ep: [],
  vol_faa_matrix_ep: [],
  vol_list_ep: [],

  earnings_faa_matrix: [],
  earnings_list: [],
  faa_earnings_list: [],

  earnings_faa_matrix_ep: [],
  earnings_list_ep: [],
  faa_earnings_list_ep: [],

  loading: false,
  loading_calculate: false,
  unsaved_changes: false,
};
export const SensitivitesReducer = (state = inititalState, action) => {
  switch (action.type) {
    case SENSITIVITIES_SET_INPUT_DATA:
      return {
        ...state,
        faa_vol_per: action.payload.faa_vol_per,
        faa_mac_per: action.payload.faa_mac_per,
        mac_per: action.payload.mac_per,
        vol_per: action.payload.vol_per,
        earnings_per: action.payload.earnings_per,
        faa_earnings_per: action.payload.faa_earnings_per,
      };

    case SENSITIVITIES_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SENSITIVITIES_SET_LOADING_calculate:
      return {
        ...state,
        loading_calculate: action.payload,
      };
    case SENSITIVITIES_SET_TABLE:
      return {
        ...state,
        vol_list: action.payload.vol_list,
        mac_list: action.payload.mac_list,
        vol_list_ep: action.payload.vol_list_ep,
        mac_list_ep: action.payload.mac_list_ep,
        earnings_list: action.payload.earnings_list,
        mac_faa_matrix: action.payload.mac_faa_matrix,
        vol_faa_matrix: action.payload.vol_faa_matrix,
        mac_faa_matrix_ep: action.payload.mac_faa_matrix_ep,
        vol_faa_matrix_ep: action.payload.vol_faa_matrix_ep,
        earnings_faa_matrix: action.payload.earnings_faa_matrix,
        faa_vol_list: action.payload.faa_vol_list,
        faa_mac_list: action.payload.faa_mac_list,
        faa_vol_list_ep: action.payload.faa_vol_list_ep,
        faa_mac_list_ep: action.payload.faa_mac_list_ep,
        faa_earnings_list: action.payload.faa_earnings_list,
        earnings_faa_matrix_ep: action.payload.earnings_faa_matrix_ep,
        earnings_list_ep: action.payload.earnings_list_ep,
        faa_earnings_list_ep: action.payload.faa_earnings_list_ep,
      };
    case SENSITIVITIES_CHANGE_INPUT: {
      let uptate = JSON.parse(JSON.stringify(state));
      uptate[action.payload.key][action.payload.index] = action.payload.value;
      return {
        ...uptate,
        unsaved_changes: true,
      };
    }
    case SENSITIVITIES_UNSAVED_CHANGES:
      return {
        ...state,
        unsaved_changes: action.payload,
      };
    case SENSITIVITIES_RESET_STATE:
      return {
        ...inititalState,
      };
    default:
      return state;
  }
};
