import React from 'react';
export default {
    project_id: "Will be generated automatically with first 4 digits for year of creation and then incremental from 1",
    project_name: "The project’s title should clearly describe and identify the capital project being evaluated. ",
    short_description: "A more detailed description of the project should be included.",
    segment: "Categorize which of the Mars business segments corresponds to the investment.",
    unit1: "From our Financial Management hierarchy, will also be used to propose contextual financials from our Financial datalake",
    unit2: "From our Financial Management hierarchy, will also be used to propose contextual financials from our Financial datalake",
    unit3: "From our Financial Management hierarchy, will also be used to propose contextual financials from our Financial datalake",
    ppm_id: "If your project is related to an existing PPM, select it in the drop down list. It will ease the post completion analysis later.",
    cab_web_id: "If you have already created the project in CAB WEB please mention here the ID. It will also ease the post completion and allow for automatic interface in future versions of the tool.",
    site: "The plant or office location where the invested capital will be constructed or improved should be selected using the drop-down options. You may need to select Multiple for composite projects.",
    project_size: "Classify the project type as Corporate major is >$30M, segment major (MW - >$5M, Other segments - >$1M), Large minor >$0.5M, small minor are the rest, based upon the size of the total investment required.",
    model_version: <p>Please select between <br/><u> Draft :</u> everything can be changed by people having access<br/><br/> <u>Ready for Circulation : </u>all figures are frozen (status of the project can be moved back to draft in Capex Alpha only and will then be interfaced with Digital IRR tool overnight) no calculation anymore even if super user change a corporate figure <br/>
    {/* Approved or Rejected: all figures are frozen (no calculation anymore).<br/> Warning: Model Version cannot be changed anymore once approved is selected.*/}
    </p>, 
    date_completed: "The date when the file was last updated should be noted in this section to record the chronology of the draft and final versions.",
    project_category: "T1 and T2 projects are considered growth project and will be requested specific growth input ",
    project_classification: "Select the one fitting the best your project. It may be used later in the tool to adapt the input requested.",
    earnings_method: "Select the appropriate earnings method (either growth or savings) in this field and choose one of the Savings options if no volume growth is expected to result from any aspect of the investment. It may be used later in the tool to adapt the input requested.",
    economic_cell: "Select the one fitting the best your project. It will be used later to inform Strategic Resource Allocation analysis (and IVCP) when extracting data from this tool.",
    initial_expenditure_year: "These fields state the year and period in which money will start to be spent on the project. ",
    initial_expenditure_period: "These fields state the year and period in which money will start to be spent on the project. ",
    initial_benefit_year: "These fields state the year and period in which money (growth or savings) will start to be earned from the project. This will be also used to start the depreciation of the asset. ",
    initial_benefit_period: "These fields state the year and period in which money (growth or savings) will start to be earned from the project. This will be also used to start the depreciation of the asset. ",
    local_tax_rate: "Please ask a finance representative (local In-country Tax center) to include the local tax relevant for this project ",
    country: "Please select the country/currency that is relevant for the project. The Mars Planning Currency Rate (MPCR) of the year will be loaded each year in the tool.  In the summary tab, there will be local currency and USD KPIs. ",
    confidentiality: "When private is selected nobody cannot read except project creator and co-authors listed. When Public is selected, all people having access to the IRR tool will have a read-only access.",
    co_authors: "You may add here people you want to allow write/edit access to your project. Please type the name and select from the people having access to the tool. If need be, the person has first to ask access with the top right button in the BPS portal. To unselect co-author, please click on the X on the right of the name."
}