import { Button } from "antd";
import React, { useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ENDPOINTS } from  "../../constants";
import { ReactComponent as ExportIcon } from "../../assets/images/export.svg";
import { ReactComponent as SaveIcon } from "../../assets/images/save.svg";
import { message } from "antd";
import CustomAxios from  "../../api.service";
import VideoHelp from "../../components/video_help";
import {useHistory } from "react-router-dom";
import { useDecodedParams } from "../../utils/param";
import { ReactComponent as Edit } from "../../assets/images/edit.svg";
import ProjectDetailEdit from "../../components/project_detail_edit";
import {
  getProjectListData,projectCreationGetDropdown
} from "../../actions/initiateProject.action";

function SaveDateCompleted(props) {
  CustomAxios(ENDPOINTS.udpateDateComplete, "POST", {"project_id": props.projectDetail.project_id })
    .then(() => {
      message.success("Saved Successfully!");
    })
    .catch(() => {
      //message.error("Unable to update date completed field");
    })
    .finally(() => {
    });
}
function ProjectTitleBar(props) {
  const projectInitiate = useSelector((state) => state.initiateProject);
 
  useEffect(() => {
    if(projectInitiate.project_list.length==0)
    getAllProjects();
  }, []);

  const dispatch = useDispatch();

  const { id } = useDecodedParams();
  const [edit, setEdit] = useState(false);
  const userInfo = useSelector((state) => state.user);
  const project= projectInitiate.project_list.find((prj) => prj.project_id==id);

  let showDetails = false;
  if (project!=undefined)
  {
    showDetails = (project.created_by === userInfo.username || project.is_co_author || userInfo.is_superuser);
  }

  let editComponent = null;
  function getAllProjects() {
    dispatch(getProjectListData());
  }
  if(edit){
    editComponent = (
      <ProjectDetailEdit
                refresh_flag={true}
                seteditMode={setEdit}
                getAllProjects={getAllProjects}
                name={false}
                is_superuser={userInfo.is_superuser}
                editMode={true}
                selectedProjectID={id}
                compare={false}
                setCompare={false}
                compareSelected={false}
                isDetailsScreen = {true}
      />
    )
  }
  return (
    <div className="right-container-top-bar">
      {editComponent}
      <Button
          disabled={!showDetails}
          className="input-button"
          type="default"
          icon={<Edit />}
          onClick={() =>
            setEdit(true)
          }
        >
          Project Details
      </Button>
      {props.export && (
        <Button
          type="default"
          icon={<ExportIcon />}
          onClick={() => props.export()}
        >
          Export
        </Button>
      )}
      {props.downloadButton && (
        <Button
          disabled={props.downloadDisabled}
          type="default"
          icon={<ExportIcon />}
          onClick={() => props.onDownload()}
        >
          Download
        </Button>
      )}
      {props.savebutton && (
        <Button
          disabled={props.saveDisabled}
          type="default"
          icon={<SaveIcon />}
          onClick={() => (props.save(),
           props.noProjectName?"":SaveDateCompleted(props))
          }
        >
          {props.saveButtonName}
        </Button>
      )}
      {props.helpVideo && 
        <VideoHelp title={props.video_title} src={props.videoLink} />
      }
      {!props.noProjectName && (
        <span>
          Project Name: <b>{props.projectDetail.project_name}</b> <br/>
          <text>* All financials values are expressed in <b>{props.projectDetail.local_currency}</b></text>
        </span>
      )}
    </div>
  );
}
ProjectTitleBar.defaultProps = {
  savebutton: true,
  saveDisabled: false,
  noProjectName: false,
  downloadButton: false,
  saveButtonName: 'Save/Calculate',
  helpVideo:false,
  videoLink:""
};
export default ProjectTitleBar;
