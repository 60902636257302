import { Col, Input, InputNumber, message, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import CustomAxios from "../../api.service";
import LoadingWrapper from "../../components/loading_wrapper";
import SpeedGraph from "../../components/speed_graph";
import Thermometer from "../../components/thermometer";
import { ENDPOINTS } from "../../constants";
import { useDecodedParams } from "../../utils/param";
import ProjectTitleBar from "../project_input/project_title_bar";
import { exportComponentAsPNG } from "react-component-export-image";
import { useDispatch, useSelector } from "react-redux";
import Thermometer_EP from "../../components/thermometer/themo_ep";
import { USER_SET_SIMULATION_ID } from "../../actions/user.action";
import savingsVideo from '../../assets/videos/Digital IRR-20211117_Simul savings.mp4';
const rowList = [
  {
    name: "",
    child: [
      {
        name: "Simulation Name",
        key: "simulation_name",
        textInput: true,
        info: "",
      },
    ],
  },
  {
    name: "Start Investment",
    child: [
      {
        name: "Year",
        key: "investment_start_year",
        info: "",
      },
    ],
  },
  {
    name: "Start Savings",
    child: [
      {
        name: "Year",
        key: "savings_start_year",
        calculated: true,
        info: "",
      },
    ],
  },
  {
    name: "Savings",
    child: [
      {
        name: "Annual savings",
        key: "annual_savings",
        info: "(kUSD)",
      },
    ],
  },
  {
    name: "Oncosts (CVS)",
    child: [
      {
        name: "CVS, one off",
        key: "oncosts_cvs",
        info: "(kUSD)",
      },
    ],
  },
  {
    name: "CAB Investment",
    child: [
      {
        name: "Capital",
        key: "capital_expense",
        info: "(kUSD)",
      },
      {
        name: "Project Expense",
        key: "project_expense",
        info: "(kUSD)",
      },
      {
        name: "Tax Rate",
        key: "tax_rate",
        info: "(%)",
      },
    ],
  },
];
const result = [
  {
    name: "Earnings",
    key: "earnings",
  },
  {
    name: "Nopat",
    key: "nopat",
  },
  {
    name: "Capital Charge",
    key: "capital_charge",
  },
  {
    name: "EP",
    key: "ep",
  },
];

function TableRows({ parent, data, handleChange }) {
  return (
    <>
      <tr>
        <td></td>
        <td></td>
      </tr>
      <tr className="sub-title">
        <td>{parent.name}</td>
        <td></td>
      </tr>
      {parent.child.map((val1) => (
        <tr key={val1.key}>
          <td>
            {val1.name} <span className="dim-info">{val1.info}</span>
          </td>
          <td>
            {val1.calculated ? (
              data[val1.key]
            ) : val1.textInput ? (
              <Input
                value={data[val1.key]}
                onChange={(e) => handleChange(val1.key, e.target.value)}
              />
            ) : (
              <InputNumber
                value={data[val1.key]}
                onChange={(e) => handleChange(val1.key, e)}
              />
            )}
          </td>
        </tr>
      ))}
    </>
  );
}
const initstate = {
  simulation_id: "",
  simulation_name: "",
  investment_start_year: "",
  capital_expense: "",
  project_expense: "",
  annual_savings: "",
  oncosts_cvs: "",
  tax_rate: "",
  "5_year_ep": "",
  capital_charge: {},
  earnings: {},
  ep: {},
  nopat: {},
  fatr: "",
  savings_start_year: "",
  years_list: [],
  unsavedChanges: false,
};
function SavingSimulation() {
  const [loading, setloading] = useState(false);
  const { id } = useDecodedParams();
  const [state, setState] = useState(initstate);
  let cardRef = useRef();
  const userState = useSelector((s) => s.user);
  const dispatch = useDispatch();
  function handleChange(key, value) {
    setState({
      ...state,
      [key]: value,
      unsavedChanges: true,
    });
  }
  function verify_input() {
    if (
      state.simulation_name !== "" &&
      state.simulation_name !== null &&
      state.investment_start_year !== "" &&
      state.investment_start_year !== null &&
      state.capital_expense !== "" &&
      state.capital_expense !== null &&
      state.project_expense !== "" &&
      state.project_expense !== null &&
      state.annual_savings !== "" &&
      state.annual_savings !== null &&
      state.oncosts_cvs !== "" &&
      state.oncosts_cvs !== null &&
      state.tax_rate !== "" &&
      state.tax_rate !== null
    ) {
      return true;
    }
    return false;
  }
  useEffect(() => {
    if (id) {
      getData(id);
    } else if (userState.saving_id) {
      getData(userState.saving_id);
    }
  }, []);
  function getData(iid) {
    setloading(true);
    CustomAxios(ENDPOINTS.savingsSimulationGet + iid, "GET")
      .then((val) => {
        let s = {
          ...state,
          ...val.data,
          investment_start_year: Number(val.data.investment_start_year),
          capital_expense: Number(val.data.capital_expense),
          project_expense: Number(val.data.project_expense),
          annual_savings: Number(val.data.annual_savings),
          oncosts_cvs: Number(val.data.oncosts_cvs),
          tax_rate: Number(val.data.tax_rate),
        };
        setState(s);
        CalculateData(iid, s);
      })
      .catch(() => {
        message.error("Unable to get Simulation");
      })
      .finally(() => {
        setloading(false);
      });
  }
  function handleSaveCalculate() {
    // const url
    if (!state.simulation_id) {
      if (verify_input()) {
        let body = { ...state };
        delete body.unsavedChanges;
        setloading(true);
        CustomAxios(ENDPOINTS.savingsSimulationSave, "POST", body)
          .then((val) => {
            setState({ ...state, simulation_id: val.data.simulation_id });
            dispatch({
              type: USER_SET_SIMULATION_ID,
              payload: { id: "saving_id", value: val.data.simulation_id },
            });
            CalculateData(val.data.simulation_id);
          })
          .catch(() => {
            message.error("Unable to Save Simulation");
          })
          .finally(() => {
            setloading(false);
          });
      }
    } else {
      if (state.unsavedChanges) {
        let body = { ...state };
        delete body.unsavedChanges;
        setloading(true);
        CustomAxios(ENDPOINTS.savingsSimulationUpdate, "POST", body)
          .then(() => {
            setState({ ...state, unsavedChanges: false });
            CalculateData();
          })
          .catch(() => {
            message.error("Unable to Update Simulation");
          })
          .finally(() => {
            setloading(false);
          });
      } else {
        CalculateData();
      }
    }
  }
  function CalculateData(simulation_id, s) {
    setloading(true);
    CustomAxios(ENDPOINTS.savingsSimulationCalculate, "POST", {
      simulation_id: simulation_id || state.simulation_id,
    })
      .then((val) => {
        setState({ ...(s ? s : state), ...val.data });
      })
      .catch(() => {
        message.error("Unable to Calculate Simulation");
      })
      .finally(() => {
        setloading(false);
      });
  }
  let ep = Number(state["5_year_ep"]) / 1000;
  return (
    <div className="simulation-container">
      <LoadingWrapper loading={loading}>
        <ProjectTitleBar
          saveDisabled={!verify_input()}
          save={handleSaveCalculate}
          projectDetail={{}}
          noProjectName={true}
          downloadButton={true}
          video={savingsVideo}
          video_title="Saving Simulation"
          onDownload={() => {
            exportComponentAsPNG(cardRef, {
              fileName: "Saving Simulation _" + state.simulation_name,
            });
          }}
          downloadDisabled={loading}
        />
        <Row
          gutter={15}
          ref={cardRef}
          style={{ backgroundColor: "#f1f1f1", padding: "10px 0" }}
        >
          <Col span={10}>
            <div className="simulation-input-container">
              <div>
                <table className="simulation-table">
                  <tbody>
                    {rowList.slice(0, 4).map((val, i) => (
                      <TableRows
                        key={i}
                        parent={val}
                        data={state}
                        handleChange={handleChange}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
              <div>
                <table className="simulation-table">
                  <tbody>
                    {rowList.slice(4).map((val, i) => (
                      <TableRows
                        key={i}
                        parent={val}
                        data={state}
                        handleChange={handleChange}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <table className="simulation-table-result">
              <thead>
                <tr>
                  <th></th>
                  {state.years_list.length > 0 ? (
                    state.years_list.map((val, i) => <th key={i}>{val}</th>)
                  ) : (
                    <>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </>
                  )}
                </tr>
                <tr>
                  <th></th>
                  {["Y0", "Y1", "Y2", "Y3", "Y4"].map((val, i) => (
                    <th key={i}>{val}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {result.map((val) => (
                  <tr key={val.key}>
                    <td>{val.name}</td>
                    {state.years_list.length > 0 ? (
                      state.years_list.map((val1, i) => (
                        <td key={i}>{state[val.key][val1]}</td>
                      ))
                    ) : (
                      <>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="simulation-steps-text">
              <h4>Assumptions used to make the calculation quicker:</h4>
              <p>
                <ul>
                  <li>
                    All calculations are done in one currency only. If you want
                    to input in local currency, all results will be in local
                    currency (even if it is said USD)
                  </li>
                  <li>
                    CAB spend in first year (starting mid-year) and project
                    spend cash out spread evenly between first investment period
                    and first savings period (assumed to be just one year after)
                  </li>
                  <li>Depreciation linear over 10 years</li>
                </ul>
              </p>
            </div>
            <div className="simulation-steps-text">
              <h4>How to populate the simulator :</h4>
              <p>
                <b>Capital</b> : CAPEX only <br />
                <b>Project expense</b> : OPEX only
                <br />
                <b>Tax Rate</b> : local rate for Net Cash flow, please consult
                your Finance Business Partner In case of doubt please use 30%
                <br />
                <b>CVS Commissioning, Validation, Start up, one off</b> : cost
                related to the installation and the ramp up of the asset
                <br />
              </p>
            </div>
          </Col>
          <Col span={14}>
            <Row gutter={15}>
              <Col>
                {/* <SpeedGraph
                  title={"5Y Cum EP"}
                  currentValueText={
                    state["5_year_ep"] ? `${state["5_year_ep"]}K USD` : "-"
                  }
                  value={
                    ep < 0
                      ? -1
                      : ep > 11
                      ? 11
                      : ep
                  }
                  customSegmentStops={[-1, 0, 2.2, 4.4, 6.6, 8.8, 11]}
                  minValue={-1}
                  maxValue={11}
                /> */}
                <Row gutter={10}>
                  <Col>
                    <Thermometer_EP value={ep} />
                  </Col>
                  <Col>
                    <Thermometer value={state.irr} />
                  </Col>
                </Row>

                <div className="simulation-steps-text">
                  <h4>Color coding and threshold</h4>
                  <p>
                    <b>IRR</b> : Above 50%, project are rated green because this
                    is the minimum for savings project, Growth Project between
                    30-50% should also be considered (yellow), below 30%
                    (orange) or below 10% (red) project return is not good
                    enough
                    <br />
                  </p>
                  <p>
                    <b>5Y cumulative Economic Profit(The 5Y is coming from the sum of the Y0-Y4 + Y4/13*6 for the 5th year)</b> : This measure is
                    absolute and therefore focus only on the scale of the return
                    (IRR is balancing the view with a ratio). If cumulative EP
                    is negative, this is obvioulsly red, if this is below 1mUSD
                    this is orange, and below 2mUSD this yellow, between 2-5mUSD
                    this is light green, >5 green and > 10m this is dark green.
                    We do not recommend to calculate (cum EP)/Assets as it is
                    duplicating KPI (and creating confusion) with IRR
                    <br />
                  </p>
                </div>
              </Col>
              {/* <Col span={12}>
                <Row>
                  <Col>
                    <Thermometer value={state.irr} />
                  </Col>
                </Row>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </LoadingWrapper>
    </div>
  );
}
export default SavingSimulation;
