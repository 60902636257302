import React, { useEffect } from "react";
import InputGroupWrapper from "../../components/input_group_wrapper";
import { ReactComponent as Recommed } from "../../assets/images/recommend_icon.svg";
import GrowthSimulation from "./growth_simulation";
import SavingSimulation from "./saving_simulation";
import { useDispatch } from "react-redux";
import { USER_SET_SIMULATION_ID } from "../../actions/user.action";
const tabsList = [
  {
    name: "Saving Simulation",
    component: <SavingSimulation />,
    icon: <Recommed />,
    key: "saving",
    alwaysShow: true,
  },
  {
    name: "Growth Simulation",
    component: <GrowthSimulation />,
    icon: <Recommed />,
    key: "grwoth",
    alwaysShow: true,
  },
];
function SimulationContainer() {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch({
        type: USER_SET_SIMULATION_ID,
        payload: { id: "saving_id", value: "" },
      });
      dispatch({
        type: USER_SET_SIMULATION_ID,
        payload: { id: "growth_id", value: "" },
      });
    };
  }, []);
  return <InputGroupWrapper tabsList={tabsList} noCheck={true} />;
}
export default SimulationContainer;
