import React, {  } from "react";
import { Modal } from "antd";

const CustomModal = ({isModalVisible, content, title, updateOk, updateCancel}) => {
    return (
        <Modal title={title} visible={isModalVisible} onOk={updateOk} onCancel={updateCancel}>
          <p>{content}</p>
        </Modal>
    );
};

export default CustomModal;
