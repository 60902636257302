import React from "react";
import { Layout } from "antd";
import Header from "../header";
const wrapperStyles = { padding: "0 30px" };
function Wrapper({ children, styles = wrapperStyles }) {
  return (
    <Layout>
      <Header />
      <Layout
        style={{
          height: "calc(100vh - 45px)",
          ...styles,
        }}
      >
        <Layout>{children}</Layout>
      </Layout>
    </Layout>
  );
}

export default Wrapper;
