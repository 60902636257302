import React from "react";
import { Layout, Menu, Dropdown } from "antd";
import marsLogo from "../../assets/images/mars-icon-m.png";
import HelpIcon from "../../assets/images/help.svg";
// import NotificationIcon from '../../assets/images/notification.svg';
import ProfileIcon from "../../assets/images/user.svg";
import Repo from "../../assets/images/repo.svg";
import FormulaHelp  from "../../components/formula_help"
import {DownloadOutlined,} from "@ant-design/icons";
import usermanual from "../../assets/user_manual/Reference_Guide.pptx"
import { ReactComponent as Edit } from "../../assets/images/edit.svg";
import { ReactComponent as Validate } from "../../assets/images/validate_icon.svg";
import { ReactComponent as Recoomd } from "../../assets/images/recommend_icon.svg";
import { Link, useLocation, withRouter } from "react-router-dom";
import CustomAxios from "../../api.service";
import { ENDPOINTS } from "../../constants";
import { useSelector } from "react-redux";
const { Header } = Layout;
function NavigationIcon() {
  const location = useLocation();
  if (location.pathname === "/") {
    return (
      <span>
        <img alt="repository" src={Repo} />
        Repository
      </span>
    );
  } else if (location.pathname.startsWith("/project_input")) {
    return (
      <span>
        <Edit />
        Input
      </span>
    );
  } else if (location.pathname.startsWith("/validate")) {
    return (
      <span>
        <Validate />
        Validate
      </span>
    );
  } else if (location.pathname.startsWith("/recommend")) {
    return (
      <span>
        <Recoomd />
        Recommend
      </span>
    );
  }
  return <></>;
}
const AccountMenu = ({ is_superuser,is_staff }) => {
  return (
    <Menu>
      {(is_superuser||is_staff) && (
        <Menu.Item>
          <Link to="/superuser">Super User</Link>
        </Menu.Item>
      )}
      <Menu.Item
        onClick={() => {
          // CustomAxios(ENDPOINTS.logout, "GET", {})
          //   .then(() => {
          //     console.error("Logging out");
          //   })
          //   .catch((err) => {
          //     console.error(err);
          //   })
          //   .finally(() => {
          //     sessionStorage.clear();
          //     localStorage.clear();
          //     // authContext.logOut();
          //     // window.location.reload();
          //   });
          window.location.replace(ENDPOINTS.logout);
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
};
const HelpMenu = () => (
  <Menu>
   <Menu.Item>
    You are lost ?  <br/>
    &nbsp; * &nbsp;Despite our videos embedded in each tab (help buttons)  you have another question ? <br/>
    &nbsp; * &nbsp;You need to look at the  <FormulaHelp/><br/>
    &nbsp; * &nbsp;You feel this tool is great but could become ever more so with a new feature ? <br/>
    &nbsp; * &nbsp;Contact : bruno.lestrat@effem.com or brian.rapp@effem.com<br/>
    &nbsp; * &nbsp;<text><a href={usermanual} download="Reference Guide">Reference Guide <DownloadOutlined/></a></text>
    </Menu.Item>
  </Menu>
);
function Index() {
  const is_superuser = useSelector((state) => state.user.is_superuser);
  const is_staff = useSelector((state) => state.user.is_staff);
  return (
    <Header className="site-header">
      <div className="header-col header-left">
        <div className="site-logo mars-logo">
          <Link to="/">
            <img src={marsLogo} alt="mars logo" />
          </Link>
        </div>
        <div className="project-name">
          <Link to="/">
            <span>IRR Platform / </span>
          </Link>
          <NavigationIcon />
        </div>
      </div>
      <div className="header-col header-center"></div>
      <div className="header-col header-right">
        {/* <div className="nav-right-link">
          <div>
          <img alt="haeder-icon" src={NotificationIcon} />
          </div>
        </div> */}
        <div className="nav-right-link">
          <div>
            <Dropdown overlay={HelpMenu} placement="bottomRight">
              <img alt="haeder-icon" src={HelpIcon} />
            </Dropdown>
          </div>
        </div> 
        <div className="nav-right-link">
          <div>
            <Dropdown
              overlay={React.cloneElement(<AccountMenu />, {
                is_superuser: is_superuser,is_staff:is_staff
              })}
              placement="bottomRight"
            >
              <img alt="haeder-icon" src={ProfileIcon} />
            </Dropdown>
          </div>
        </div>
      </div>
    </Header>
  );
}

export default withRouter(Index);
