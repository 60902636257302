import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers";
import thunk from "redux-thunk";
// import logger from 'redux-logger';
const middlewares = [
  thunk,
  // logger
];
/* eslint-disable no-undef */
const composeEnhancers =
  typeof window === "object" &&
  process.env.REACT_APP_ENV === "dev" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;
/* eslint-enable no-undef */

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares)
  // other store enhancers if any
);
export default createStore(reducers, enhancer);
