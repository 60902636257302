import {
  KBPS_INPUT_EDIT_YEAR_INPUT,
  KBPS_INPUT_RESET_STATE,
  KBPS_INPUT_SET_LOADING,
  KBPS_INPUT_SET_TABLE,
  KBPS_INPU_SET_TABLE_UNSAVED_CHANGES,
} from "../actions/kbps.actions";

const initailState = {
  year_column: [],
  loading: false,
  data: {},
  unsaved_changes: false,
};

export const KbpsinputReducer = (state = initailState, action) => {
  switch (action.type) {
    case KBPS_INPUT_SET_TABLE: {
      let d = action.payload;
      if (d.years && d.project_id) {
        let year = [...d.years];
        delete d.years;
        delete d.project_id;
        return {
          ...state,
          data: d,
          year_column: year,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case KBPS_INPUT_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case KBPS_INPUT_EDIT_YEAR_INPUT: {
      let data = { ...state.data };
      data[action.payload.key][action.payload.year] = action.payload.value;
      return {
        ...state,
        data,
        unsaved_changes: true,
      };
    }
    case KBPS_INPU_SET_TABLE_UNSAVED_CHANGES:
      return {
        ...state,
        unsaved_changes: action.payload,
      };
    case KBPS_INPUT_RESET_STATE:
      return {
        ...initailState,
      };
    default:
      return state;
  }
};
